import React from 'react';
import { ResponsiveContainerOuterSolid } from '../../ui/Containers';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { RegularButton } from '../../ui/Buttons';

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const UserLoginForm = (props) => {
    const [username, setUsername] = React.useState ('');
    const [password, setPassword] = React.useState ('');

	const { locale } = useSelector (state => state.page);

    function handleChangeUsername (event) {
        setUsername (event.target.value);
    };

    function handleChangePassword (event) {
        setPassword (event.target.value);
    }

    return (
        <ResponsiveContainerOuterSolid
            sx={{
                borderRadius: '3px',
                padding: '1em',
                maxWidth: '60vw'
            }}
        >
            <Grid container spacing={2} justifyContent='center' alignItems='center' sx={{height: '100%', position: 'relative'}}>
                <Grid item xs={12} /><Grid item xs={1}><IconButton onClick={props.close} sx={{position: 'absolute', top: '5%', right: '0%'}}><CloseIcon /></IconButton></Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>{ locale.login.headingText }</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        onChange={handleChangeUsername} 
                        placeholder={locale.login.usernameLabel} 
                        sx={{width: '80%'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <PersonIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        type='password' 
                        onChange={handleChangePassword} 
                        placeholder={locale.login.passwordLabel} 
                        sx={{width: '80%'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <VisibilityIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid> 
                <Grid item xs={12}>
                    <RegularButton 
                        onClick={() => props.onSubmit (username, password)}
                        variant='contained'
                        sx={{width: '80%'}}
                    >
                        { locale.login.loginButtonLabel }
                    </RegularButton>
                </Grid>
                <Alert severity='error' variant='standard' sx={{visibility: props.showError ? 'visible' : 'hidden'}}>{locale.login.wrongCredentialsText}</Alert>
            </Grid>
        </ResponsiveContainerOuterSolid>
    )
};

export default UserLoginForm;