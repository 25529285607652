export function objIsEmpty (object) {
    return Object.keys (object).length === 0;
}

export function arrIsEmpty (array) {
    return array.length === 0;
}

export function validateCancellationCode (code) {
    if (code.length !== 6) {
        return false;
    }

    return true;
}