import { createTheme } from "@mui/material/styles"

const adminFontFamily = "'Poppins', sans-serif"
const defaultFontSize = 14;
const companyFontSize = 22;

function generatePageTheme (options) {
    const primaryOpacity = ("0" + (Math.floor (options.colors.primary.opacity / 100 * 256) - 1).toString (16)).slice (-2);
    const secondaryOpacity = ("0" + (Math.floor (options.colors.secondary.opacity / 100 * 256) - 1).toString (16)).slice(-2);

    const theme = createTheme ({
        palette: {
            primary: {
                main: options.colors.primary.main + primaryOpacity,
                light: options.colors.primary.light + primaryOpacity,
                dark: options.colors.primary.dark + primaryOpacity
            },
            secondary: {
                main: options.colors.secondary.main + secondaryOpacity,
                light: options.colors.secondary.light + secondaryOpacity,
                dark: options.colors.secondary.dark + secondaryOpacity
            },
            stepper: {
                primary: options.colors.stepper.primary,
                secondary: options.colors.stepper.secondary
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        },
        typography: {
            company: {
                fontFamily: options.typography.h1.fontFamily,
                fontSize: companyFontSize,
                fontWeight: 800
            },
            h1: {
                fontFamily: options.typography.h1.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h2: {
                fontFamily: options.typography.h2.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h3: {
                fontFamily: options.typography.h3.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h4: {
                fontFamily: options.typography.h4.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h5: {
                fontFamily: options.typography.h5.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h6: {
                fontFamily: options.typography.h6.fontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            body1: {
                fontFamily: options.typography.body1.fontFamily,
                fontSize: defaultFontSize
            },
            body2: {
                fontFamily: options.typography.body2.fontFamily,
                fontSize: defaultFontSize
            },
            button: {
                fontFamily: options.typography.button.fontFamily,
                fontSize: defaultFontSize
            },
            caption: {
                fontFamily: options.typography.caption.fontFamily,
                fontSize: 10
            },
            overline: {
                fontFamily: options.typography.overline.fontFamily,
                fontSize: defaultFontSize
            }
        }
    });

    return theme;
}

function getDefaultTheme () {
    return createTheme ({});
}

function getAdminTheme () {
    const theme = createTheme ({
        palette: {
            primary: {main: '#000000'},
            secondary: {main: '#ffffff'}
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        },
        typography: {
            h1: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h2: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h3: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h4: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h5: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            h6: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize,
                fontWeight: 'bold'
            },
            body1: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize
            },
            body2: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize
            },
            button: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize
            },
            caption: {
                fontFamily: adminFontFamily,
                fontSize: 10
            },
            overline: {
                fontFamily: adminFontFamily,
                fontSize: defaultFontSize
            }
        }
    });

    return theme;
}

export { generatePageTheme, getDefaultTheme, getAdminTheme };