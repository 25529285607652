import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const OptionalComponent = (props) => {
    if (props.render) {
        return props.children;
    }

    return null;
}

const MutableComponent = (props) => {
    return props.options[props.selected];
}

const LoadedComponent = (props) => {
    return (
        <>
        {props.children}
            <Backdrop
                sx={{color: 'white'}}
                open={!props.loaded}
            >
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export { OptionalComponent, LoadedComponent };