import HeadquartersOptionsForm from './forms/HeadQuarters';
import ModalityOptionsForm from './forms/Modalities';
import ServiceOptionsForm from './forms/Services';
import ReservationDateForm from './forms/DateTimeStaff';
import UserDataForm from './forms/UserData';
import SuccessForm from './forms/Success';

import reservationPages from '../reservations/forms/pages.json';
import { useSelector } from 'react-redux';

/* 
    Wrapper element to invoke any of the available declared forms.
    The form that will be rendered depends on the step index stored,
    and this component will return a valid form if it evaulates to one of the 
    values declared as proerties of the 'reservationPages' object.
*/
const ReservationForm = () => {
    let form;

    const { step } = useSelector (state => state.stepper);

    switch (step) {
        case reservationPages.HEADQUARTERS:
            form = <HeadquartersOptionsForm />;
            break;

        case reservationPages.SERVICE:
            form = <ServiceOptionsForm />;
            break;

        case reservationPages.MODALITY:
            form = <ModalityOptionsForm />;
            break;

        case reservationPages.DATE:
            form = <ReservationDateForm />;
            break;
        
        case reservationPages.USERDATA:
            form = <UserDataForm />;
            break;

        case reservationPages.SUCCESS:
            form = <SuccessForm />
            break;

        default: 
            form = <div>No component for step {step}</div>
            break;
    }

    return form;
};

export { ReservationForm, reservationPages };