import React from 'react'

/* React Router DOM */
import { useLoaderData } from 'react-router-dom';

/* Custom Components */
import DataHandler from './DataHandler';
import AuthenticationRequiredPage from '../AuthenticationRequired';

const EditPage = (props) => {
    const loadedDataRef = useLoaderData ();
    let loadedData;

    loadedDataRef.forEach ((doc) => {
        loadedData = doc.data ();
    })

    return (
        <AuthenticationRequiredPage loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} >
            <DataHandler data={loadedData} mode="edit" setSaveSuccess={props.setSaveSuccess}/>
        </AuthenticationRequiredPage>
    )
}

export default EditPage;