import React, { useEffect, useState } from 'react'

/* MUI Icons Material */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

/* MUI Material Components */
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


/* API Functions */
import { deletePageById, getAllPagesCount, getPageNextBatch } from '../../../api/pages/api';
import AuthenticationRequiredPage from '../AuthenticationRequired';
import { LoadedComponent } from '../../../components/wrappers';


/* Custom Components */
import { FilledIconButton } from '../../../components/ui/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { setCount, setCurrentPages, setCurrentPagination, addReference, removeReference } from '../../../redux/admin/admin_pages/adminPages';

import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

var operation = '+';

const PageTableActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleBackPage (e) {
        onPageChange (page - 1);
        operation = '-';
    }

    function handleNextPage (e) {
        onPageChange (page + 1);
        operation = '+';
    }

    return (
        <Stack direction="row">
            <IconButton
                onClick={handleBackPage}
                disabled={page === 0}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextPage}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                <KeyboardArrowRight />
            </IconButton>
        </Stack>
    )
}


const AdminPage = () => {
    const [drawerMenu, setDrawerMenu] = useState (false);
    const [pageToDelete, setPageToDelete] = useState (null);
    const [showDeleteDialog, setShowDeleteDialog] = useState (false);
    const [pagesLoaded, setPagesLoaded] = useState (false);
    const [currentPage, setCurrentPage] = useState (0);


    const {pages, pagination, references, count} = useSelector (state => state.adminPages);
    const {loggedIn} = useSelector (state => state.admin);

    const dispatcher = useDispatch ();

    useEffect (() => {
        getAllPagesCount ()
        .then ((res) => { dispatcher (setCount (Math.ceil (res))) })
        .catch ((err) => console.log (err));
    }, []);

    useEffect (() => {
        getPageData ();
    }, [currentPage]);

    function getPageData () {
        setPagesLoaded (false);
        console.log (references)
        getPageNextBatch (5, references[currentPage - 1])
        .then ((res) => {
            let newPages = [];

            res.forEach ((doc) => {
                newPages.push (doc.data ());
            })

            dispatcher (setCurrentPages (newPages));
            if (operation === '+') {
                dispatcher (addReference (newPages[newPages.length - 1].docID));
            } else {
                dispatcher (removeReference ());
            }
            
            setPagesLoaded (true);
        })
        .catch ((err) => console.log (err));
    }

    function handleClickDelete (pageId) {
        setShowDeleteDialog (true);
        setPageToDelete (pageId);
    }

    function deletePage () {
        const pageId = pageToDelete;

        deletePageById (pageId)
        .then (res => {
            alert ('Página eliminada correctamente');
            window.location.href = '/';
        })
        .catch ((err) => console.log (err));
    }

    function handlePageChange (index) {
        setCurrentPage (index);
        dispatcher (setCurrentPagination(index));
    }
    
    return (
        <AuthenticationRequiredPage loggedIn={loggedIn}>
            <LoadedComponent loaded={pagesLoaded}>
                <Box sx={{height: '90vh', background: 'gainsboro'}}>
                    <Grid 
                        container
                        spacing={2}
                        sx={{'& > .MuiGrid-item': {pl: '0px'}}}
                    >
                        <Grid item xs={0} md={1} />
                        <Grid
                            item container
                            xs={12} md={10} 
                            sx={{m: '1em'}}
                            spacing={2}
                        >
                            <Grid item xs={11}>
                                <TableContainer 
                                    component={Paper}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Nombre</strong></TableCell>
                                                <TableCell><strong>URL</strong></TableCell>
                                                <TableCell><strong>Opciones</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                pages.map ((page, idx) => {
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell>{page.businessName}</TableCell>
                                                            <TableCell><Link target="_blank" href={`https://${page.subdomain}.${process.env.REACT_APP_BOOKEA_DOMAIN}`}>https://{page.subdomain}.{process.env.REACT_APP_BOOKEA_DOMAIN}</Link></TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={() => window.location.href = `/page/${page.docID}/edit`}><EditIcon /></IconButton>
                                                                <IconButton onClick={() => handleClickDelete (page.docID)}><DeleteIcon /></IconButton> 
                                                                <IconButton onClick={() => window.location.href = `/page/${page.docID}/statistics`}><BarChartIcon /></IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPage={5}
                                                    rowsPerPageOptions={[5]}
                                                    page={pagination}
                                                    count={count}
                                                    onPageChange={handlePageChange}
                                                    ActionsComponent={PageTableActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={0} md={1}>
                                <FilledIconButton
                                    onClick={() => window.location.href = '/page'}
                                >
                                    <AddIcon />
                                </FilledIconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={0} md={1} />
                    </Grid>
                </Box>
            </LoadedComponent>
            <Modal
                open={showDeleteDialog}
                onClose={() => setPageToDelete (null)}
            >
                <Stack direction='row' sx={{height: '100%', alignItems:'center'}}>
                <Container component={Paper} 
                    sx={{width: '60%', p: '1em'}}
                >
                    <Stack sx={{alignItems: 'center'}} spacing={2}>
                        <Typography variant='h6'>Seguro que deseas eliminar esta página?</Typography>
                        <Typography variant='body1'>Una vez eliminada, no podrás recuperarla</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={3} />
                                <Grid item xs={2} sx={{alignItems: 'center'}}>
                                    <Button 
                                        variant='contained'
                                        onClick={deletePage}
                                    >
                                        Eliminar
                                    </Button>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2}>
                                    <Button 
                                        variant='text'
                                        onClick={() => this.setState ({showDeleteDialog: false})}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            <Grid item xs={3} />
                        </Grid>
                    </Stack>
                </Container>
                </Stack>
            </Modal>
        </AuthenticationRequiredPage>
    );
}

export default AdminPage;