import { useState, useEffect, Fragment } from 'react';
import { LoadedComponent, OptionalComponent } from '../../wrappers';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { RegularButton } from '../../ui/Buttons';

import UserLoginForm from './UserLogin';

import { userLogin, getUserById } from '../../../api/user/api';

import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../redux/business/user/userSlice';
import { increaseStep, setShowBack } from '../../../redux/business/stepper/stepperSlice';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore/lite';
import { postReservation } from '../../../api/reservations/api';
import { setAppHeight } from '../../../redux/business/page/pageSlice';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { generateCancellationCode } from '../../../utils/generators';
/* 
    User Data Forms must include at least the following fields as
    part of the props object:
    -   fullName
    -   phoneNumber
    -   email
    -   dni
*/
const UserDataForm = () => {
    const showFullName = true;
    const showPhone = true;
    const showEmail = true;

    const dispatcher = useDispatch ();
    const { fullName, phone, email, uid, loggedIn } = useSelector (state => state.user);
    const { business, service, modality, date, employee } = useSelector (state => state.reservation);

    const [activeName, setActiveName] = useState ({value: fullName, display: fullName});
    const [activePhone, setActivePhone] = useState ({value: phone, display: phone});
    const [activeEmail, setActiveEmail] = useState ({value: email, display: email});
    const [activeUID, setActiveUID] = useState ({value: uid, display: uid});
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState (false);

    const validPhone = RegExp (/^\d{9}$/);
    const validEmail = RegExp (/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);

    const [nameEmpty, setNameEmpty] = useState (activeName.value.length === 0);
    const [phoneError, setPhoneError] = useState (false);
    const [phoneEmpty, setPhoneEmpty] = useState (activePhone.value.length === 0);
    const [emailError, setEmailError] = useState (false);
    const [emailEmpty, setEmailEmpty] = useState (activeEmail.value.length === 0);

    const [disableNext, setDisableNext] = useState (true);
    const [loginModal, setLoginModal] = useState (false);
    const [loading, setLoading] = useState (false);
    const [loginError, setLoginError] = useState (false);
	const [reservationError, setReservationError] = useState (null);

	const { locale } = useSelector (state => state.page);

    useEffect (() => {
        const app = document.getElementById ('App');
        dispatcher (setAppHeight (app.getBoundingClientRect ().height));
    }, []);

    useEffect (() => {
        setDisableNext (nameEmpty || phoneError || phoneEmpty || emailError || emailEmpty || !termsAndConditionsChecked);
    }, [nameEmpty, phoneError, phoneEmpty, emailError, emailEmpty, termsAndConditionsChecked]);

    useEffect (() => {
        dispatcher (setShowBack (!loginModal));
    }, [loginModal]);

    function validatePhone (value) {
        return validPhone.test (value);
    }

    function validateEmail (value) {
        return validEmail.test (value);
    }

    function handleChangeField (event) {
        let field = event.target.name;
        let value = event.target.value;

        processField (field, value);
    };

    function processField (field, value) {
        switch (field) {
            case 'name':
                setActiveName ({value: value, display: value});
                if (value.length === 0) {
                    setNameEmpty (true);
                } else {
                    setNameEmpty (false);
                }
                break;
            case 'phone':
                setActivePhone ({value: value, display: value});
                if (value.length === 0) {
                    setPhoneEmpty (true);
                } else {
                    setPhoneEmpty (false);
                }

                if (validatePhone (value)) {
                    setPhoneError (false);
                } else {
                    setPhoneError (true);
                }
                break;
            case 'email':
                setActiveEmail ({value: value, display: value});
                if (value.length === 0) {
                    setEmailEmpty (true);
                } else {
                    setEmailEmpty (false);
                }

                if (validateEmail (value)) {
                    setEmailError (false);
                } else {
                    setEmailError (true);
                }
                break;
            case 'uid':
                setActiveUID ({value: value, display: value});
                break;

            default: 
                break;
        }
    }

    function handleLogin (username, password) {
        setLoading (true);
        userLogin (username, password)
        .then ((user) => {
            if (user.status !== 200) {
                setLoginError (true);
                setLoading (false);
                return;
            }

            const uid = user.uid;

            getUserById (uid)
            .then ((user) => {
                if (!user) {
                    setLoginError (true);
                    return;
                }

                dispatcher (setUser ({
                    fullName: user.name,
                    email: user.email,
                    phone: user.phoneNumber,
                    uid: uid,
                    loggedIn: true
                }))
                
                processField ('name', user.name);
                processField ('email', user.email);
                processField ('phone', user.phoneNumber);
                processField ('uid', uid);
                setLoading (false);
                setLoginModal (false);
            })
            .catch ((err) => console.log (err));
        })
        .catch ((err) => {
            console.log (err);
            setLoginError (true);
        })
    }

    async function handleConfirm () {
        const dayjsDate = dayjs (`${date.date} ${date.time.display}`, 'DD/MM/YYYY HH:mm');
        const cancellationCode = generateCancellationCode ();

        const body = {
            address: business.businessAddress,
            amount: service.price,
            businessID: business.businessId,
            businessName: business.businessName,
            businessEmail: business.businessEmail,
            businessImage: business.businessImage,
            date: Timestamp.fromMillis (dayjsDate.valueOf ()),
            time: date.time.display,
            startTime: Timestamp.fromMillis (dayjs (`${dayjsDate.format ('DD/MM/YYYY')} ${date.time.display}`, 'DD/MM/YYYY HH:mm').valueOf ()),
            endTime: Timestamp.fromMillis (dayjs (`${dayjsDate.format ('DD/MM/YYYY')} ${date.time.display}`, 'DD/MM/YYYY HH:mm').add (service.serviceDuration, 'minute').valueOf ()),
            sortTime: dayjs ().valueOf (),
            matchDate: dayjsDate.format ("DD/MM/YYYY"),
            serviceID: service.serviceId,
            serviceBooked: service.name,
            serviceDuration: service.serviceDuration.toString(),
            categoryID: business.businessCategories[0],
            employeeID: employee.employeeId,
            employeeName: employee.employeeName,
            userName: activeName.value,
            userID: activeUID.value,
            userEmail: activeEmail.value,
            userNumber: activePhone.value,
            bookSlotTime: Timestamp.fromMillis (dayjs()),
            isConfirmed: false,
            isPending: true,
            isBusy: true,
            cancellationCode: cancellationCode
        };

		try {
			setLoading (true);
        	await postReservation (body);
        	setLoading (false);
        	dispatcher (increaseStep ());
		} catch (err) {
			console.error (err);
			setReservationError (err.response.data.data);
			setLoading (false);
		}
        
    }

    function handleShowLogin () {
        setLoginModal (true);
        dispatcher (setShowBack (false));
    }

    function handleCloseLogin () {
        setLoginModal (false);
        dispatcher (setShowBack (true));
    }

    return (
        <Fragment>
            <LoadedComponent loaded={!loading}>
                <Grid 
                    container 
                    spacing={2}
                    sx={{
                        visibility: loginModal ? 'hidden' : 'visible',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <Grid item xs={12} md={6} sx={{height: '100%'}}>
                        <Stack spacing={2} alignItems='center'>
                            <Typography variant='h6'>{ locale.stepper.userData.userData.headingText }</Typography>
                            <OptionalComponent render={showFullName}>
                                <TextField 
                                    value={activeName.value}
                                    variant='outlined'
                                    label={loggedIn ? null : locale.stepper.userData.userData.fullNameLabel}
                                    name='name'
                                    onChange={handleChangeField}
                                    required
                                    sx={{width: '90%'}}
                                    disabled={loggedIn}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <PersonIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </OptionalComponent>
                            <OptionalComponent render={showPhone}>
                                <TextField
                                    value={activePhone.value}
                                    variant='outlined'
                                    label={loggedIn ? null : locale.stepper.userData.userData.phoneLabel}
                                    name='phone'
                                    onChange={handleChangeField}
                                    required
                                    error={phoneError}
                                    helperText={phoneError ? locale.stepper.userData.userData.invalidPhoneText : false}
                                    sx={{width: '90%'}}
                                    disabled={loggedIn}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <PhoneIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </OptionalComponent>
                            <OptionalComponent render={showEmail}>
                                <TextField 
                                    value={activeEmail.value}
                                    variant='outlined' 
                                    label={loggedIn ? null : locale.stepper.userData.userData.emailLabel} 
                                    name='email' 
                                    onChange={handleChangeField} 
                                    error={emailError}
                                    helperText={emailError ? locale.stepper.userData.userData.invalidEmailText : false}
                                    sx={{width: '90%'}}
                                    disabled={loggedIn}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <EmailIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </OptionalComponent>
                            <Typography variant='body1' sx={{visibility: loggedIn ? 'hidden' : 'visible', width: '90%'}}>{ locale.stepper.userData.userData.accountPrefixText } <a onClick={handleShowLogin} style={{textDecoration: 'underline', cursor: 'pointer'}}>{ locale.stepper.userData.userData.openAccountText }</a> { locale.stepper.userData.userData.accountSuffixText }</Typography>
                        </Stack>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        md={6}
                    >
                        <Grid 
                            container 
                            spacing={2} 
                            justifyContent='center' 
                            sx={{ml: 0, '& > .MuiGrid-item': {pl: 0}}}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>{ locale.stepper.userData.reservationData.headingText }</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{p: 0, border: 'none', width: '30%'}}><Typography variant='body1' sx={{fontWeight: 'bold'}}>{ locale.stepper.userData.reservationData.serviceLabel }:</Typography></TableCell>
                                                    <TableCell sx={{p: 0, border: 'none', width: '70%'}}><Typography variant='body1'>{service.name}</Typography></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{p: 0, border: 'none', width: '30%'}}><Typography variant='body1' sx={{fontWeight: 'bold'}}>{ locale.stepper.userData.reservationData.dateLabel }:</Typography></TableCell>
                                                    <TableCell sx={{p: 0, border: 'none', width: '70%'}}><Typography variant='body1'>{date.date}</Typography></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{p: 0, border: 'none', width: '30%'}}><Typography variant='body1' sx={{fontWeight: 'bold'}}>{ locale.stepper.userData.reservationData.timeLabel }:</Typography></TableCell>
                                                    <TableCell sx={{p: 0, border: 'none', width: '70%'}}><Typography variant='body1'>{date.time.display}</Typography></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{p: 0, border: 'none', width: '30%'}}><Typography variant='body1' sx={{fontWeight: 'bold'}}>{ locale.stepper.userData.reservationData.staffLabel }:</Typography></TableCell>
                                                    <TableCell sx={{p: 0, border: 'none', width: '70%'}}><Typography variant='body1'>{employee.employeeName}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value={termsAndConditionsChecked} onChange={() => setTermsAndConditionsChecked (!termsAndConditionsChecked)} />} label={<>{ locale.stepper.userData.reservationData.termsPrefixText } <Link target="_blank" href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}>{ locale.stepper.userData.reservationData.openTermsText }</Link></>} />
                            </Grid>
                            <Grid item xs={12}>
                                <RegularButton
                                    variant='contained'
                                    onClick={() => handleConfirm ()}
                                    sx={{width: '90%'}}
                                    disabled={disableNext}
                                >
                                    { locale.stepper.confirmButtonText }
                                </RegularButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Backdrop
                    open={loginModal}
                >
                    <UserLoginForm onSubmit={handleLogin} close={handleCloseLogin} showError={loginError}/>
                </Backdrop>
            </LoadedComponent>
			<Snackbar
				anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				autoHideDuration={10000}
				open={!!reservationError}
				onClose={() => setReservationError (null)}
				message={reservationError}
			>
				<Alert onClose={() => setReservationError (null)} severity="error" sx={{ width: '100%' }}>
					{ reservationError }
				</Alert>
			</Snackbar>
        </Fragment>
    );
};

export default UserDataForm;