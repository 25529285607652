import { createSlice } from "@reduxjs/toolkit";
import { getLocale } from "../../../locale/locale";

const pageInitialState = {
    title: "",
    businesses: [],
    theme: {},
	language: "es",
	locale: getLocale ("es"),
	showPopup: false,
	popup: '',
    appHeight: '100%'
};

export const pageSlice = createSlice ({
    name: 'page',
    initialState: pageInitialState,
    reducers: {
        setTitle: (state, action) => {
            return {
                ...state,
                title: action.payload
            };
        },
        setBusinesses: (state, action) => {
            return {
                ...state,
                businesses: action.payload
            };
        },
        setTheme: (state, action) => {
            return {
                ...state,
                theme: action.payload
            };
        },
		setLanguage: (state, action) => {
			return {
				...state,
				language: action.payload
			};
		},
		setLocale: (state, action) => {
			return {
				...state,
				locale: action.payload
			};
		},
		setShowPopup: (state, action) => {
			return {
				...state,
				showPopup: action.payload
			};
		},
		setPopup: (state, action) => {
			return {
				...state,
				popup: action.payload
			};
		},
        setAppHeight: (state, action) => {
            return {
                ...state,
                appHeight: action.payload
            };
        },
        resetPage: (state) => {
            return pageInitialState;
        }
    }
});

export const { setTitle, setBusinesses, setTheme, setLanguage, setLocale, setAppHeight, setShowPopup, setPopup, resetPage } = pageSlice.actions;