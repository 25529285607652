import React, { useState } from 'react';

/* Custom Components */
import AdminEditForm from '../../../components/admin/forms/EditForm';

/* MUI Material Components */
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

/* API functions */
import { createPage, getAllBusinesses, patchPageById } from '../../../api/pages/api';
import { getAllPageFiles } from '../../../api/files/api';

const DataHandler = (props) => {
    /* States for general metadata */
    const [businessName, setBusinessName] = React.useState (props.mode === 'create' ? '' : props.data.businessName);
    const [subdomain, setSubDomain] = React.useState (props.mode === 'create' ? '' : props.data.subdomain);
    const [steps, setSteps] = React.useState (props.mode === 'create' ? [] : props.data.steps);
    const [stepsChanged, setStepsChanged] = React.useState (false);         // Necessary for re-render after modifying steps
	const [showPopup, setShowPopup] = React.useState (props.mode === 'create' ? false : props.data.showPopup);
	const [popup, setPopup] = React.useState (props.mode === 'create' ? '' : props.data.popup);

    /* State for Colors */
    const [primaryMain, setPrimaryMain] = React.useState (props.mode === 'create' ? '#000000' : props.data.theme.colors.primary.main);
    const [primaryLight, setPrimaryLight] = React.useState (props.mode === 'create' ? '#000000' : props.data.theme.colors.primary.light);
    const [primaryDark, setPrimaryDark] = React.useState (props.mode === 'create' ? '#000000' : props.data.theme.colors.primary.dark);
    const [primaryOpacity, setPrimaryOpacity] = React.useState (props.mode === 'create' ? 100 : props.data.theme.colors.primary.opacity);
    const [secondaryMain, setSecondaryMain] = React.useState (props.mode === 'create' ? '#ffffff' : props.data.theme.colors.secondary.main);
    const [secondaryLight, setSecondaryLight] = React.useState (props.mode === 'create' ? '#ffffff' : props.data.theme.colors.secondary.light);
    const [secondaryDark, setSecondaryDark] = React.useState (props.mode === 'create' ? '#ffffff' : props.data.theme.colors.secondary.dark);
    const [secondaryOpacity, setSecondaryOpacity] = React.useState (props.mode === 'create' ? 100 : props.data.theme.colors.secondary.opacity);
    const [stepperPrimary, setStepperPrimary] = React.useState (props.mode === 'create' ? '#ffffff' : props.data.theme.colors.stepper.primary);
    const [stepperSecondary, setStepperSecondary] = React.useState (props.mode === 'create' ? '#ffffff' : props.data.theme.colors.stepper.secondary)

    /* State for Fonts */
    const [company, setCompany] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.company.fontFamily);
    const [companyURL, setCompanyURL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.company.fontURL);
    const [h1, setH1] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h1.fontFamily);
    const [h1URL, setH1URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h1.fontURL);
    const [h2, setH2] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h2.fontFamily);
    const [h2URL, setH2URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h2.fontURL);
    const [h3, setH3] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h3.fontFamily);
    const [h3URL, setH3URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h3.fontURL);
    const [h4, setH4] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h4.fontFamily);
    const [h4URL, setH4URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h4.fontURL);
    const [h5, setH5] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h5.fontFamily);
    const [h5URL, setH5URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h5.fontURL);
    const [h6, setH6] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h6.fontFamily);
    const [h6URL, setH6URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.h6.fontURL);
    const [body1, setBody1] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.body1.fontFamily);
    const [body1URL, setBody1URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.body1.fontURL);
    const [body2, setBody2] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.body2.fontFamily);
    const [body2URL, setBody2URL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.body2.fontURL);
    const [button, setButton] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.button.fontFamily);
    const [buttonURL, setButtonURL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.button.fontURL);
    const [caption, setCaption] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.caption.fontFamily);
    const [captionURL, setCaptionURL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.caption.fontURL);
    const [overline, setOverline] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.overline.fontFamily);
    const [overlineURL, setOverlineURL] = React.useState (props.mode === 'create' ? '' : props.data.theme.typography.overline.fontURL);

    /* State for media resources */
    const [background, setBackground] = React.useState (props.mode === 'create' ? '' : props.data.theme.background);
    const [logo, setLogo] = React.useState (props.mode === 'create' ? '' : props.data.theme.logoUrl);
    const [pageUrl, setPageUrl] = React.useState (props.mode === 'create' ? '' : props.data.theme.logoRedirect);
    const [fileOptions, setFileOptions] = React.useState ([]);
    const [filesLoaded, setFilesLoaded] = React.useState (false);

    const [activeBusiness, setActiveBusiness] = React.useState (props.mode === 'create' ? [] : props.data.businessID);
    const [businessOptions, setBusinessOptions] = React.useState ([]);
    const [updated, setUpdated] = React.useState (false);

    let formControls = {
        businessName: {value: businessName, setter: (e) => setBusinessName (e.target.value)},
        businessID: {value: activeBusiness, setter: (e) => setActiveBusiness (e.target.value)},
        subdomain: {value: subdomain, setter: (e) => setSubDomain (e.target.value)},
        steps: {value: steps, setter: setSteps},
        stepsChanged: {value: stepsChanged, setter: setStepsChanged},
		showPopup: {value: showPopup, setter: (e) => setShowPopup (!showPopup)},
		popup: {value: popup, setter: (e) => setPopup (e.target.value)},
        primaryMain: {value: primaryMain, setter: (e) => setPrimaryMain (e.target.value)},
        primaryLight: {value: primaryLight, setter: (e) => setPrimaryLight (e.target.value)},
        primaryDark: {value: primaryDark, setter: (e) => setPrimaryDark (e.target.value)},
        primaryOpacity: {value: primaryOpacity, setter: (e) => setPrimaryOpacity (e.target.value)},
        secondaryMain : {value: secondaryMain, setter: (e) => setSecondaryMain (e.target.value)},
        secondaryLight: {value: secondaryLight, setter: (e) => setSecondaryLight (e.target.value)},
        secondaryDark: {value: secondaryDark, setter: (e) => setSecondaryDark (e.target.value)},
        secondaryOpacity: {value: secondaryOpacity, setter: (e) => setSecondaryOpacity (e.target.value)},
        stepperPrimary: {value: stepperPrimary, setter: (e) => setStepperPrimary (e.target.value)},
        stepperSecondary: {value: stepperSecondary, setter: (e) => setStepperSecondary (e.target.value)},
        background: {value: background, setter: (e) => setBackground (e.target.value)},
        logo: {value: logo, setter: (e) => setLogo (e.target.value)},
        pageUrl: {value: pageUrl, setter: (e) => setPageUrl (e.target.value)},
        typography: {value: {
            company: {fontFamily: company, fontURL: companyURL},
            h1: {fontFamily: h1, fontURL: h1URL},
            h2: {fontFamily: h2, fontURL: h2URL},
            h3: {fontFamily: h3, fontURL: h3URL},
            h4: {fontFamily: h4, fontURL: h4URL},
            h5: {fontFamily: h5, fontURL: h5URL},
            h6: {fontFamily: h6, fontURL: h6URL},
            body1: {fontFamily: body1, fontURL: body1URL},
            body2: {fontFamily: body2, fontURL: body2URL},
            button: {fontFamily: button, fontURL: buttonURL},
            caption: {fontFamily: caption, fontURL: captionURL},
            overline: {fontFamily: overline, fontURL: overlineURL}
        }, setter: handleTypographyChange}
    };

    React.useEffect (() => {
        getAllBusinesses ()
        .then ((res) => {
            let businesses = [];
            res.forEach ((doc) => {
                businesses.push (doc.data ());
            })

            setBusinessOptions (businesses);
            setUpdated (!updated);
        })
        .catch ((err) => console.log (err));

        getAllPageFiles ()
        .then ((res) => {
            setFileOptions (res);
            setFilesLoaded (true);
            setUpdated (!updated);
        })
        .catch ((err) => console.log (err))
    }, [])

    React.useEffect (() => {
        if (filesLoaded) {
            setBackground (props.mode === 'create' ? fileOptions[0].src : background);
            setLogo (props.mode === 'create' ? fileOptions[0].src : logo);
        }
    }, [fileOptions]);

    React.useEffect (() => {
        formControls = {
            businessName: {value: businessName, setter: (e) => setBusinessName (e.target.value)},
            businessID: {value: activeBusiness, setter: (e) => setActiveBusiness (e.target.value)},
            subdomain: {value: subdomain, setter: (e) => setSubDomain (e.target.value)},
            steps: {value: steps, setter: setSteps},
            stepsChanged: {value: stepsChanged, setter: setStepsChanged},
			showPopup: {value: showPopup, setter: (e) => setShowPopup (e.target.value)},
			popup: {value: popup, setter: (e) => setPopup (e.target.value)},
            primaryMain: {value: primaryMain, setter: (e) => setPrimaryMain (e.target.value)},
            primaryLight: {value: primaryLight, setter: (e) => setPrimaryLight (e.target.value)},
            primaryDark: {value: primaryDark, setter: (e) => setPrimaryDark (e.target.value)},
            primaryOpacity: {value: primaryOpacity, setter: (e => setPrimaryOpacity (e.target.value))},
            secondaryMain : {value: secondaryMain, setter: (e) => setSecondaryMain (e.target.value)},
            secondaryLight: {value: secondaryLight, setter: (e) => setSecondaryLight (e.target.value)},
            secondaryDark: {value: secondaryDark, setter: (e) => setSecondaryDark (e.target.value)},
            secondaryOpacity: {value: secondaryOpacity, setter: (e) => setSecondaryOpacity (e.target.value)},
            stepperPrimary: {value: stepperPrimary, setter: (e) => setStepperPrimary (e.target.value)},
            stepperSecondary: {value: stepperSecondary, setter: (e) => setStepperSecondary (e.target.value)},
            background: {value: background, setter: (e) => setBackground (e.target.value)},
            logo: {value: logo, setter: (e) => setLogo (e.target.value)},
            pageUrl: {value: pageUrl, setter: (e) => setPageUrl (e.target.value)},
            typography: {value: {
                company: {fontFamily: company, fontURL: companyURL},
                h1: {fontFamily: h1, fontURL: h1URL},
                h2: {fontFamily: h2, fontURL: h2URL},
                h3: {fontFamily: h3, fontURL: h3URL},
                h4: {fontFamily: h4, fontURL: h4URL},
                h5: {fontFamily: h5, fontURL: h5URL},
                h6: {fontFamily: h6, fontURL: h6URL},
                body1: {fontFamily: body1, fontURL: body1URL},
                body2: {fontFamily: body2, fontURL: body2URL},
                button: {fontFamily: button, fontURL: buttonURL},
                caption: {fontFamily: caption, fontURL: captionURL},
                overline: {fontFamily: overline, fontURL: overlineURL}
            }, setter: handleTypographyChange}
        }
    }, [subdomain, steps, showPopup, popup, primaryMain, primaryLight, primaryDark, secondaryMain, secondaryLight, secondaryDark, background, logo, pageUrl]);

    function handleSubmit () {
        const body = {
            businessID: activeBusiness,
            businessName: businessName,
            subdomain: subdomain,
            steps: steps,
			showPopup: showPopup,
			popup: popup,
            theme: {
                background: background,
                colors: {
                    primary: {
                        main: primaryMain,
                        light: primaryLight,
                        dark: primaryDark,
                        opacity: primaryOpacity
                    },
                    secondary: {
                        main: secondaryMain,
                        light: secondaryLight,
                        dark: secondaryDark,
                        opacity: secondaryOpacity
                    },
                    stepper: {
                        primary: stepperPrimary,
                        secondary: stepperSecondary
                    }
                },
                typography: {
                    company: {
                        fontFamily: company,
                        fontURL: companyURL
                    },
                    h1: {
                        fontFamily: h1,
                        fontURL: h1URL
                    },
                    h2: {
                        fontFamily: h2,
                        fontURL: h2URL
                    },
                    h3: {
                        fontFamily: h3,
                        fontURL: h3URL
                    },
                    h4: {
                        fontFamily: h4,
                        fontURL: h4URL
                    },
                    h5: {
                        fontFamily: h5,
                        fontURL: h5URL
                    },
                    h6: {
                        fontFamily: h6,
                        fontURL: h6URL
                    },
                    body1: {
                        fontFamily: body1,
                        fontURL: body1URL
                    },
                    body2: {
                        fontFamily: body2,
                        fontURL: body2URL
                    },
                    button: {
                        fontFamily: button,
                        fontURL: buttonURL
                    },
                    caption: {
                        fontFamily: caption,
                        fontURL: captionURL
                    },
                    overline: {
                        fontFamily: overline,
                        fontURL: overlineURL
                    }
                },
                logoUrl: logo,
                logoRedirect: pageUrl
            }
        }
        
        if (props.mode === 'create') {
            createPage (body)
            .then ((res) => {
                alert ('Página creada correctamente');
                window.location.href = '/';
            })
            .catch ((err) => console.log (err));
        } else {
            body.docID = props.data.docID;
            patchPageById (props.data.docID, body)
            .then ((res) => {
                alert ('Cambios guardados Correctamente');
                window.location.href = '/';
            })
            .catch ((err) => console.log (err));
        }
        
    }

    function handleTypographyChange (typography) {
        setCompany (typography.company.fontFamily);
        setCompanyURL (typography.company.fontURL);
        setH1 (typography.h1.fontFamily);
        setH1URL (typography.h1.fontURL);
        setH2 (typography.h2.fontFamily);
        setH2URL (typography.h2.fontURL);
        setH3 (typography.h3.fontFamily);
        setH3URL (typography.h3.fontURL);
        setH4 (typography.h4.fontFamily);
        setH4URL (typography.h4.fontURL);
        setH5 (typography.h5.fontFamily);
        setH5URL (typography.h5.fontURL);
        setH6 (typography.h6.fontFamily);
        setH6URL (typography.h6.fontURL);
        setBody1 (typography.body1.fontFamily);
        setBody1URL (typography.body1.fontURL);
        setBody2 (typography.body2.fontFamily);
        setBody2URL (typography.body2.fontURL);
        setButton (typography.button.fontFamily);
        setButtonURL (typography.button.fontURL);
        setCaption (typography.caption.fontFamily);
        setCaptionURL (typography.caption.fontURL);
        setOverline (typography.overline.fontFamily);
        setOverlineURL (typography.overline.fontURL);
    }

    return (
        <Stack 
            spacing={2}
            sx={{background: 'gainsboro', minHeight: '100vh'}}
        >
            <Typography variant='h4'>{props.mode === 'create' ? "Crea una nueva página" : `Modifica la página ${businessName}`}</Typography>
            <AdminEditForm 
                onSubmit={handleSubmit} 
                controls={formControls} 
                mode={props.mode} 
                businesses={businessOptions}
                files={fileOptions}
            />
        </Stack>
    )
}

export default DataHandler;