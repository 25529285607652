import { useEffect, useState } from 'react';

/* Components */
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { RegularButton } from '../../components/ui/Buttons';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import { StandardReservationStepper } from '../../components/reservations/Stepper';
import { ImageLogo } from '../../components/reservations/Logo';
import { ResponsiveContainerOuter } from '../../components/ui/Containers';
import { ToggleTypography } from '../../components/ui/Typography';
import { ReactComponent as BookeaLogo } from '../../media/logo/Logo SVG Bookea.svg'
import UserLoginForm from '../../components/reservations/forms/UserLogin';
import { LoadedComponent } from '../../components/wrappers';
import { ImagePopup } from "../../components/reservations/ImagePopup"

/* API request related functions */
import { getPageElementsBySubdomain } from '../../api/pages/api';
import { getBusinessById } from '../../api/reservations/api';
import { userLogin, getUserById } from '../../api/user/api';

/* Theme Functions */
import { generatePageTheme, getDefaultTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material/styles'

import PersonIcon from '@mui/icons-material/Person';

/* Redux imports */
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/business/user/userSlice';
import { increaseStep, setStepList } from '../../redux/business/stepper/stepperSlice';
import { setBusinesses, setTheme, setTitle, setAppHeight, setLanguage, setLocale, setShowPopup, setPopup } from '../../redux/business/page/pageSlice';
import { setBusiness } from '../../redux/business/reservation/reservationSlice';

import { reservationPages } from '../../components/reservations/Form';
import { getLocale } from '../../locale/locale';


const BusinessPage = ({subdomain}) => {
    const [pageLoading, setPageLoading] = useState (true);
    const [imageLoading, setImageLoading] = useState (true);
    const [showLogin, setShowLogin] = useState (false);
    const [loginLoading, setLoginLoading] = useState (false);
    const [loginError, setLoginError] = useState (false);
    const [muiTheme, setMuiTheme] = useState (getDefaultTheme ());

    const dispatcher = useDispatch ();
    const { theme, title, appHeight, showPopup, popup, language, locale } = useSelector (state => state.page);
    const { step } = useSelector (state => state.stepper);
    const { loggedIn } = useSelector (state => state.user);

    function setHeading (data) {
        const curFavicon = document.getElementById ('favicon');
        const curTitle = document.getElementById ('page-title');

        curFavicon.href = data.theme.logoUrl;
        curTitle.innerText = data.businessName;
    }

    useEffect (() => {
        getPageElementsBySubdomain (subdomain)
        .then ((res) => {
            res.forEach ((doc) => {
                const data = doc.data ();

                setHeading (data);
                setPageLoading (false);
                setMuiTheme (generatePageTheme (data.theme));
                dispatcher (setStepList (data.steps));
                dispatcher (setTheme (data.theme));
                dispatcher (setBusinesses (data.businessID));
				dispatcher (setShowPopup (data.showPopup === undefined ? false : data.showPopup));
				dispatcher (setPopup (data.popup === undefined ? '' : data.popup));
                dispatcher (setTitle (data.businessName));
                loadFonts (data.theme.typography);
                
                if (data.businessID.length === 1) {
                    getBusinessById (data.businessID[0])
                    .then ((business) => {
                        dispatcher (setBusiness ({
                            businessId: business.docID,
                            businessCategories: business.categoryID,
                            businessName: business.businessName,
                            businessEmail: business.email,
                            businessImage: business.logo,
                            businessAddress: business.address,
							showEmployees: business.showEmployee
                        }));
                    })
                }
            })
        })
        .catch ((err) => console.log (err));

		
    }, [])

    function loadFonts (fonts) {
        let fontURLS = new Set ();
        
        Object.entries (fonts).forEach (([typography, font]) => {
            fontURLS.add (font.fontURL);
        })

        fontURLS.forEach ((url) => {
            let header = document.getElementsByTagName ('head')[0];
            let fontLink = document.createElement ('link');
            fontLink.rel = 'stylesheet';
            fontLink.href = url;
            header.appendChild (fontLink);
        })
    }

	function handleSelectLanguage (event) {
		const lang = event.target.value;

		dispatcher (setLanguage (lang));
		dispatcher (setLocale (getLocale (lang)));
	}

    function handleLogin (username, password) {
        setLoginLoading (true);

        userLogin (username, password)
        .then ((user) => {
            if (user.status !== 200) {
                setLoginError (true);
                setLoginLoading (false);
                return;
            }

            const uid = user.uid;

            getUserById (uid)
            .then ((user) => {
                if (!user) {
                    setLoginError (true);
                    return;
                }
                
                dispatcher (setUser ({
                    fullName: user.name,
                    email: user.email,
                    phone: user.phoneNumber,
                    uid: user.docID,
                    loggedIn: true
                }));
                
                setLoginLoading (false);
                setShowLogin (false);
            })
            .catch ((err) => console.log (err));
        })
        .catch ((err) => {
            console.log (err);
            setLoginError (true);
        })
    }

    function handleContinue () {
        dispatcher (increaseStep ());
    }

    const app = document.getElementById ('App');
    const image = document.getElementById ('background-img');
    window.onresize = () => {
        if (app && image) {
            if (app.getBoundingClientRect ().height !== image.getBoundingClientRect ().height) {
                dispatcher (setAppHeight (app.getBoundingClientRect ().height));
            }
        }
    }

    return (
        <>
        <Box
            id='loader-background'
            sx={{
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                display: pageLoading || imageLoading ? 'flex' : 'none'
            }}
        >
            <Stack sx={{position: 'relative'}}>
                <BookeaLogo id="bookea-logo" />
                <Typography 
                    variant='caption'
                    id='loader-caption'
                >Powered by Bookea</Typography>
            </Stack>
        </Box>
        <ThemeProvider theme={muiTheme}>
            <img 
                src={theme.background}
                id="background-img"
                onLoad={() => setImageLoading (false)}
                alt="background"
                style={{
                    height: app ? appHeight : '100%',
                    width: '100vw',
                    zIndex: -10000,
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    objectFit: 'cover'
                }}
            />
            <Box
                sx={{
                    minHeight: '100vh',
                    alignItems: 'center',
                    display: pageLoading || imageLoading ? 'none' : 'flex'
                }}
            >
                <Box
                    sx={{margin: 'auto', paddingTop: '50px'}}
                >
                    {
                        step !== reservationPages.HOME ? 
                        <StandardReservationStepper /> : 
                        <ResponsiveContainerOuter
                            sx={{
                                borderRadius: '3px 3px 3px 3px',
                                boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)',
                                padding: '1em',
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} sx={{margin: 'auto', position: 'relative', width: '100%', '&>.MuiGrid-item': {paddingTop: '8px', paddingLeft: '0px'}}}>
                                        <Grid item container md={4} xs={5}>
                                            {
                                                !loggedIn && <>
                                                    <Grid item md={2} xs={2}>
                                                        <PersonIcon onClick={() => setShowLogin (true)} sx={{cursor: 'pointer'}} />
                                                    </Grid>
                                                    <Grid item md={9} xs={8} alignItems='center' justifyContent='center' >
                                                        <ToggleTypography variant='body1' textAlign='left' lineHeight='25px' onClick={() => setShowLogin (true)} sx={{cursor: 'pointer'}}>
                                                            { locale.home.accountLabel }
                                                        </ToggleTypography>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                        <Grid item md={4} xs={2} />
                                        <Grid item md={4} xs={5} display="flex" justifyContent="flex-end">
											<FormControl variant="standard">
												<Select value={language} onChange={handleSelectLanguage} sx={{minWidth: '120px', '::before': {border: "none"}}}>
													<MenuItem value="es">Español</MenuItem>
													<MenuItem value="en">English</MenuItem>
												</Select>
											</FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <ImageLogo logo={theme.logoUrl} logoRedirect={theme.logoRedirect} alt={"Logo de la empresa"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction='column' spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant='body1'>{ locale.home.titlePrefix }</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='company' sx={{paddingBottom: '1.5em'}} translate="no">{title}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: '1em'}}>
                                    <Grid container direction="column" spacing={3} sx={{mb: '1em', '&>.MuiGrid-item': {paddingTop: '0px'}}}>
                                        <Grid item xs={6} sx={{paddingTop: '24px'}}>
                                            <RegularButton
                                                variant='contained'
                                                onClick={handleContinue}
                                                sx={{width: '90%'}}
                                            >
                                                { locale.home.reservationButtonLabel }
                                            </RegularButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='caption'>
                                                Powered by Bookea
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
							<Backdrop
								open={showPopup}
							>
								<ImagePopup img={popup} close={() => dispatcher (setShowPopup (false))}/>
							</Backdrop>
                        </ResponsiveContainerOuter>
                    }
                </Box>
            </Box>
            <LoadedComponent loaded={!loginLoading}>
                <Backdrop
                    open={showLogin}
                >
                    <UserLoginForm onSubmit={handleLogin} close={() => setShowLogin (false)} showError={loginError}/>
                </Backdrop>
            </LoadedComponent>
        </ThemeProvider>
        </>
    )
}

export default BusinessPage;