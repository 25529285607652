const env = process.env.REACT_APP_ENV;

const fbConfig = {
    apiKey: process.env[`REACT_APP_${env}_FIREBASE_API_KEY`],
    authDomain: process.env[`REACT_APP_${env}_FIREBASE_AUTH_DOMAIN`],
    projectId: process.env[`REACT_APP_${env}_FIREBASE_PROJECT_ID`],
    storageBucket: process.env[`REACT_APP_${env}_FIREBASE_STORAGE_BUCKET`],
    messagingSenderId: process.env[`REACT_APP_${env}_FIREBASE_MESSAGING_SENDER_ID`],
    appId: process.env[`REACT_APP_${env}_FIREBASE_APP_ID`],
    measurementId: process.env[`REACT_APP_${env}_FIREBASE_MEASUREMENT_ID`]
};

export default fbConfig;