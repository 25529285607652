import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { RegularButton } from '../../ui/Buttons';
import LabeledSelect from '../../ui/Select';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import ResponsiveLocationDisplayMap from '../../ui/Map';

import { getMultipleBusinessByIdArray } from '../../../api/reservations/api';
import { LoadedComponent } from '../../wrappers';
import { businessInitialState, setBusiness, setBusinessList } from '../../../redux/business/reservation/reservationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { increaseStep } from '../../../redux/business/stepper/stepperSlice';
import { arrIsEmpty } from '../../../utils/validation';
import { businessesAreEqual } from '../../../utils/comparison';
import { setAppHeight } from '../../../redux/business/page/pageSlice';

const HeadquartersOptionsForm = () => {
    const [selectedValue, setSelectedValue] = useState (0);
    const [optionsLoaded, setOptionsLoaded] = useState (true);
    const [mapLoaded, setMapLoaded] = useState (false);

    const dispatcher = useDispatch ();
    const { businesses, locale } = useSelector (state => state.page);
    const { businessList } = useSelector (state => state.reservation);

    useEffect (() => {
        const app = document.getElementById ('App');
        dispatcher (setAppHeight (app.getBoundingClientRect ().height));

        if (arrIsEmpty (businessList) || businessesAreEqual (businessList[0], businessInitialState)) {
            setOptionsLoaded (false);
            getMultipleBusinessByIdArray (businesses)
            .then ((businesses) => {
                let newOptions = [];

                businesses.forEach ((data) => {
                    newOptions.push ({
                        businessId: data.docID, 
                        businessName: data.businessName, 
                        businessAddress: data.address, 
                        businessEmail: data.email,
                        businessCategories: data.categoryID,
                        businessImage: data.logo,
						showEmployees: data.showEmployee,
                        lat: data.lat, 
                        lng: data.lng,
                    });
                });
                dispatcher (setBusinessList (newOptions))
                setOptionsLoaded (true);
            })
            .catch ((err) => console.log (err))
        } else {
            setOptionsLoaded (true);
        }
    }, []);

    useEffect (() => {
        if (optionsLoaded) {
            setSelectedValue (0);
        }
    }, [businessList]);

    function handleSelect (event) {
        setSelectedValue (event.target.value);
    }

    function handleContinue () {
        dispatcher (setBusiness (businessList[selectedValue]));
        dispatcher (increaseStep ());
    }

    return (
        <LoadedComponent loaded={optionsLoaded && mapLoaded}>
            <Box>
                <Stack 
                    spacing={4}
                    sx={{alignItems: 'center'}}
                >
                    <Typography variant='h6' sx={{pr: '1em'}}>{ locale.stepper.headquarters.headingText }</Typography>
                    <Grid 
                        container 
                        columnSpacing={0} 
                        justifyContent='center'
                        alignItems='stretch'
                        sx={{margin: 'auto', '&>.MuiGrid-item': {pl: '0px'}}}
                    >
                        <Grid item xs={12} md={6}>
                            <Stack 
                                spacing={3}
                                sx={{height: '100%', alignItems: 'center', '&>:not(style)+:not(style)': {mb: '10px', mt: '0px'}}}
                            >
                                <LabeledSelect inputLabel="Sede" width="100%" left='10%'>
                                    <Select
                                        value={selectedValue}
                                        label={locale.stepper.headquarters.selectLabel}
                                        onChange={handleSelect}
                                        defaultValue={selectedValue}
                                        disabled={businessList.length === 0}
                                        sx={{width: '80%', margin: 'auto'}}
                                        placeholder='Local'
                                    >
                                        {
                                            businessList.map ((option, idx) => {
                                                return <MenuItem key={idx} value={idx}>{option.businessName}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </LabeledSelect>
                                <Stack direction="row" spacing={3} sx={{pt: '20px', mb: '10px', width: '80%'}} alignItems='start'>
                                    <LocationOnIcon />
                                    <Typography variant='body1' sx={{lineHeight: '1.5em', textAlign: 'center', mt: '10px'}}>{businessList[selectedValue].businessAddress}</Typography>
                                </Stack>
                                <div style={{paddingTop: '45px', width: '100%'}}>
                                    <RegularButton
                                        variant='contained'
                                        onClick={handleContinue}
                                        sx={{width: '80%', mt: '3em'}}
                                    >
                                        { locale.stepper.continueButtonText }
                                    </RegularButton>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ResponsiveLocationDisplayMap lat={optionsLoaded ? businessList[selectedValue].lat : 0} lng={ optionsLoaded ? businessList[selectedValue].lng : 0} setLoaded={setMapLoaded} />
                        </Grid> 
                    </Grid>
                </Stack>
            </Box>
        </LoadedComponent>
    );
};

export default HeadquartersOptionsForm;