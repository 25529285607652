import { createSlice } from "@reduxjs/toolkit";

export const businessInitialState = {
    businessId: "",
    businessCategories: [],
    businessName: "",
    businessEmail: "",
    businessImage: "",
    businessAddress: "",
	showEmployees: true
};

export const serviceInitialState = {
    serviceId: "",
    serviceDuration: 0,
    price: 0,
    name: ""
};

export const hourInitialState = {
    hours: -1,
    minutes: -1
};

export const dateInitialState = {
    date: "",
    time: {}
};

export const employeeInitialState = {
    employeeName: "",
    docID: ""
};

const reservationInitialState = {
    business: businessInitialState,
    service: {},
    modality: {},
    date: dateInitialState,
    employee: employeeInitialState,
    businessList: [ businessInitialState ],
    serviceList: [ serviceInitialState ],
    hourList: [ hourInitialState ],
    employeeList: [ employeeInitialState ]
};

export const reservationSlice = createSlice ({
    name: 'reservation',
    initialState: reservationInitialState,
    reducers: {
        setBusiness: (state, action) => {
            return {
                ...state,
                business: action.payload
            };
        },
        setService: (state, action) => {
            return {
                ...state,
                service: action.payload
            };
        },
        setModality: (state, action) => {
            return {
                ...state,
                modality: action.payload
            };
        },
        setDate: (state, action) => {
            return {
                ...state,
                date: action.payload
            };
        },
        setEmployee: (state, action) => {
            return {
                ...state,
                employee: action.payload
            };
        },
        setBusinessList: (state, action) => {
            return {
                ...state,
                businessList: action.payload
            }
        },
        setServiceList: (state, action) => {
            return {
                ...state,
                serviceList: action.payload
            };
        },
        setHourList: (state, action) => {
            return {
                ...state,
                hourList: action.payload
            };
        },
        setEmployeeList: (state, action) => {
            return {
                ...state,
                employeeList: action.payload
            };
        },
        resetReservation: (state) => {
            return reservationInitialState;
        }
    }
});

export const { setBusiness, setService, setModality, setDate, setEmployee, setBusinessList, setServiceList, setHourList, setEmployeeList, resetReservation } = reservationSlice.actions;