import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography';

const ToggleTypography = styled (Typography) (({theme}) => ({
    [theme.breakpoints.up ('xs')]: {
        display: 'none'
    },
    [theme.breakpoints.up ('md')]: {
        display: 'block'
    }
}))

export {ToggleTypography};