import React from 'react';

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import MobileStepper from '@mui/material/MobileStepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const ResponsiveMobileStepper = styled (MobileStepper) (({theme}) => ({
    [theme.breakpoints.up ('xs')]: {
        '&>.MuiMobileStepper-dots': {margin: 'auto'}
    }
}))

const ResponsiveRegularStepper = styled (Stepper) (({theme}) => {
    return {
        margin: 'auto',
        '& .MuiSvgIcon-root': {
            width: '1.5em',
            height: '1.5em',
            color: theme.palette.stepper.secondary
        },
        '& .Mui-active' : {
            color: `${theme.palette.stepper.primary} !important`
        },
        '& .MuiStepLabel-label': {
            fontSize: '12px',
            color: theme.palette.stepper.secondary
        },
        '& .Mui-completed': {
            color: `${theme.palette.stepper.primary} !important`
        },
        '& .MuiStepConnector-root': {
            top: '17px',
            width: '50%',
            margin: 'auto'
        },

        [theme.breakpoints.up ('xs')]: {
            maxWidth: '85vw',
        },
        [theme.breakpoints.up ('sm')]: {
            maxWidth: '80vw',
        },
        [theme.breakpoints.up ('md')]: {
            maxWidth: '70vw',
        }
    }
})

const ResponsiveStepper = (props) => {
    const theme = useTheme ();
    const mobile = useMediaQuery (theme.breakpoints.down ('sm'));
    
	const { language } = useSelector (state => state.page);

    return mobile ? 
    (
        <ResponsiveMobileStepper
            variant='dots'
            steps={props.steps.length}
            activeStep={props.activeStep}
        />
    ) : (
        <ResponsiveRegularStepper
            activeStep={props.activeStep} 
            alternativeLabel
        >
            {
                props.steps.map ((step, key) => {
                    return (
                        <Step key={key}>
                            <StepLabel optional={step.optional ? <Typography variant='caption'>Opcional</Typography> : <></>}>{step.label[language]}</StepLabel>
                        </Step>
                    )
                })
            }
        </ResponsiveRegularStepper>
    )
}

export default ResponsiveStepper;