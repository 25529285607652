import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
    fullName: "",
    email: "",
    phone: "",
    uid: "",
    loggedIn: false
};

export const userSlice = createSlice ({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        },
        resetUser: state => {
            return userInitialState;
        }
    }
})

export const { setUser, resetUser } = userSlice.actions;