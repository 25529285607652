import { getFirestore, collection, getDocs, where, query, doc } from 'firebase/firestore/lite';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { app, auth } from '../fb_init';

const db = getFirestore (app);

async function loginAdmin (username, password) {
    try {
        const loginRes = await signInWithEmailAndPassword (auth, username, password);
        const adminCollection = collection (db, 'adminCollection');
        const admin = await getDocs (query (adminCollection, where ('email', '==', username)));
        
        return admin._docs.length === 0 ? null : admin.docs[0].data();
    } catch (err) {
        console.log (err);
        return null;
    }
    
}

export {
    loginAdmin
};