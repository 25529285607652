import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, getDocs, collection, doc, query, where } from 'firebase/firestore/lite';

import { app, auth } from '../fb_init';

const db = getFirestore (app);

async function userLogin (username, password) {
    try {
        const res = await signInWithEmailAndPassword (auth, username, password);
        let user = res.user;
        user.status = 200;
        return user;
    } catch (err) {
        const errorCode = err.code.split ('/')[1];

        return (errorCode === 'user-not-found') || (errorCode === 'wrong-password') ? 
            {status: 400, message: 'wrong-credentials'} : {status: 500, message :'other-error'};
    }
}

async function getUserById (uid) {
    try {
        const userCollection = collection (db, 'userCollection');
        const res = await getDocs (query (userCollection, where ('docID', '==', uid)))
        let user;

        res.forEach ((doc) => {
            user = doc.data ();
        });

        return user;
    } catch (err) {
        return null;
    }
}

export {
    userLogin,
    getUserById
}