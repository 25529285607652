import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { useDispatch } from 'react-redux';
import { setAppHeight } from '../../redux/business/page/pageSlice';

const GoogleMapRenderer = (props) => {
    const ref = React.useRef (null);
    const [map, setMap] = React.useState ();
    const lat = props.lat;
    const lng = props.lng;
    const [latLng, setLatLng] = React.useState (new window.google.maps.LatLng (props.lat, props.lng));
    const [marker, setMarker] = React.useState (new window.google.maps.Marker ({position: latLng, map}));

    const dispatcher = useDispatch ();
    const app = document.getElementById ('App');

    React.useEffect(() => {
        if (window.google) {
            if (ref.current && !map) {
                setMap (new window.google.maps.Map (ref.current, {zoom: 15, center:{lat: props.lat, lng: props.lng}}));
            }
        }
    }, []);

    React.useEffect (() => {
        if (window.google && map) {
            props.setLoaded (true);
            marker.setMap (null);
            map.setCenter (latLng);
            setMarker (new window.google.maps.Marker ({position: latLng, map}));
            dispatcher (setAppHeight (app.getBoundingClientRect ().height))
        }
    }, [latLng])

    React.useEffect (() => {
        setLatLng (new window.google.maps.LatLng (lat, lng));
    }, [lat, lng])

    return (
        <div 
            ref={ref} 
            style={{minHeight: props.height, minWidth: props.width, maxWidth: '80%', margin: 'auto', borderRadius: '3px'/*, boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)'*/}} 
        />
    );
}

const LocationDisplayMap = (props) => {
    
    return (
        <Wrapper apiKey={process.env.REACT_APP_GMAPS_API_KEY}>
            <GoogleMapRenderer height={'250px'} width={'50px'} lat={props.lat} lng={props.lng} setLoaded={props.setLoaded}/>
        </Wrapper>
    )
}

export { LocationDisplayMap };