import { useState, Fragment } from 'react';

import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ResponsiveContainerOuter } from '../ui/Containers';
import { ReservationForm } from './Form';
import ResponsiveStepper from '../ui/Stepper';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ArrowBack } from '@mui/icons-material';
import { FilledIconButton } from '../ui/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { decreaseStep, increaseStep } from '../../redux/business/stepper/stepperSlice';

const StandardReservationStepper = (props) => {
    const theme = useTheme ();
    const dispatcher = useDispatch ();
    const { step, stepList, showBack } = useSelector (state => state.stepper);

    function handleBack () {
        dispatcher (decreaseStep ());
    };

    return (
        <Fragment>
            <Grid container spacing={3} justifyContent='center' alignItems='center'>
                <Grid item xs={12} sx={{marginTop: '1em'}}>
                    <ResponsiveStepper activeStep={step} steps={stepList} />
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainerOuter
                        sx={{
                            borderRadius: '3px 3px 3px 3px',
                            boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)',
                            position: 'relative',
                            paddingTop: '2.5em',
                            paddingBottom: '3em'
                        }}
                        id="outer-container"
                    >
                        <ReservationForm/>
                            {
                                useMediaQuery (theme.breakpoints.down ('md')) ? 
                                <FilledIconButton
                                    onClick={handleBack}
                                    sx={{position: 'absolute', left: '-1em', top: '-1em', visibility: showBack ? 'visible' : 'hidden'}}
                                >
                                    <ArrowBack />
                                </FilledIconButton> :
                                <IconButton
                                    onClick={handleBack}
                                    sx={{position: 'absolute', left: '30px', top: '33px', visibility: showBack ? 'visible' : 'hidden'}}
                                >
                                    <ArrowBackIosNewIcon sx={{width: '.8em', height: '.8em', color: 'black'}} />
                                </IconButton>
                            }
                        </ResponsiveContainerOuter>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export { StandardReservationStepper };