export function businessesAreEqual (objBusiness1, objBusiness2) {
    return objBusiness1.businessId === objBusiness2.businessId;
}

export function servicesAreEqual (objService1, objService2) {
    return objService1.serviceId === objService2.serviceId;
}

export function employeesAreEqual (objEmployee1, objEmployee2) {
    return objEmployee1.docID === objEmployee2.docID;
}

export function hourIsGt (objHour1, objHour2) {
    const result = parseInt (objHour1.hours) > parseInt (objHour2.hours) || (parseInt (objHour1.hours) === parseInt (objHour2.hours) && parseInt (objHour1.minutes) > parseInt (objHour2.minutes));
    return result ? 1 : -1;
}

export function hourIsGeq (objHour1, objHour2) {
	const result = parseInt (objHour1.hours) >= parseInt (objHour2.hours) || (parseInt (objHour1.hours) === parseInt (objHour2.hours) && parseInt (objHour1.minutes) >= parseInt (objHour2.minutes));
    return result ? 1 : -1;
}

export function  cancellationCodeMatches (code, reservation) {
    return code === reservation.cancellationCode;
}