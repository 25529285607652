import react from 'react';
import { styled } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';

const ResponsiveDrawer = styled (Drawer) (({theme}) => ({
    [theme.breakpoints.up ('xs')]: {
        width: '85vw',
    },
    [theme.breakpoints.up ('sm')]: {
        width: '80vw',
    },
    [theme.breakpoints.up ('md')]: {
        width: '70vw',
        height: '80vh',
    }
}))

export default ResponsiveDrawer;