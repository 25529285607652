import React from 'react';
import LoginPage from './pages/LoginPage';
import { useSelector } from 'react-redux';

const AuthenticationRequiredPage = (props) => {
    const {loggedIn} = useSelector (state => state.admin);
    const stored = localStorage.getItem ('bookea-logged-in');

    return stored  ? (
        props.children
    ) : (
        <LoginPage />
    )
}

export default AuthenticationRequiredPage;