import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const LabeledSelect = (props) => {
    return (
        <FormControl sx={{width: props.width}}> 
            <InputLabel sx={{left: props.left}}>{props.inputLabel}</InputLabel>
                {
                    props.children
                }
        </FormControl>
    )
}

export default LabeledSelect;