import es from "./es.json";
import en from "./en.json";

export function getLocale (lang) {
	let localeObject = null;

	switch (lang) {
		case "es":
			localeObject = es;
			break;

		case "en":
			localeObject = en;
			break;

		default:
			localeObject = es;
			break;
	}

	return localeObject;
}