import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import { RegularButton } from '../../ui/Buttons';
import LabeledSelect from '../../ui/Select';

import dayjs from "dayjs";

import { BookeaCalendarPicker, BlocksTimePicker } from '../DatePicker';
import { 
	getHoursAndStaffByBusinessDate,
	getHoursByBusinessDateStaff,
	getDaysByBusinessId,
	getStaffByBusinessServiceHours 
} from '../../../api/reservations/api';

import { LoadedComponent } from '../../wrappers';

import { useDispatch, useSelector } from 'react-redux';
import { setDate, setEmployee, setHourList } from '../../../redux/business/reservation/reservationSlice';
import { increaseStep } from '../../../redux/business/stepper/stepperSlice';
import { setAppHeight } from '../../../redux/business/page/pageSlice';

const ReservationDateForm = () => {
    const defaultDate = dayjs ();

    const [activeDate, setActiveDate] = useState ({value: defaultDate, display: defaultDate.format ('DD/MM/YYYY')});
    const [activeTime, setActiveTime] = useState ({value: {hours: defaultDate.hour (), minutes: defaultDate.minute ()}, display: `${defaultDate.hour ()}:${defaultDate.minute ()}`});
    const [workerOptions, setWorkerOptions] = useState ([]);
    const [activeWorker, setActiveWorker] = useState (0);
    const [workingDays, setWorkingDays] = useState ([]);

    const [timeLoaded, setTimeLoaded] = useState (false);
    const [workersLoaded, setWorkersLoaded] = useState (false);
    const [workingDaysLoaded, setWorkingDaysLoaded] = useState (false);

    const [disableNext, setDisableNext] = useState (true);
    const [workerIsSelected, setWorkerIsSelected] = useState (false);
    const [timeIsSelected, setTimeIsSelected] = useState (false);

    const dispatcher = useDispatch ();
    const { business, service, hourList } = useSelector (state => state.reservation);
	const { showEmployees } = business;
	const { locale } = useSelector (state => state.page);

    useEffect (() => {
        const app = document.getElementById ('App');
        dispatcher (setAppHeight (app.getBoundingClientRect ().height));

        getHoursAndStaffByBusinessDate (business.businessId, service.serviceId, activeDate.value)
        .then (({ hours, staff }) => {
			staff = [{employeeName: locale.stepper.date.anyAvailableLabel, docID: ''}, ...staff];

            if (staff.length === 1) {
                setWorkerOptions ([{employeeName: locale.stepper.date.noneAvailableLabel, docID: ''}]);
            } else {
				if (showEmployees) {
					setWorkerOptions (staff);
				} else {
					setWorkerOptions ([{employeeName: locale.stepper.date.anyAvailableLabel, docID: ''}, {}]);
				}
            }
            setWorkersLoaded (true);
            dispatcher (setHourList (hours));
            setTimeLoaded (true);
        })
        .catch ((err) => console.log (err));

        getDaysByBusinessId (business.businessId)
        .then ((res) => {
            setWorkingDays (res);
            setWorkingDaysLoaded (true);
        })
        .catch ((err) => console.log (err));
    }, []);

    useEffect (() => {
        setDisableNext (!((workerIsSelected || activeWorker === 0) && timeIsSelected))
    }, [activeDate, activeTime, activeWorker]);

    useEffect (() => {
        if (workersLoaded && !timeIsSelected) {
            if (workerOptions.length === 1) {
                setActiveWorker (0);
                dispatcher (setHourList ([]));
            } else {
                setActiveWorker (0);
            }
        }
    }, [workerOptions]);

    function handleDateChange (event) {
        setActiveDate ({value: event, display: event.format ('DD/MM/YYYY')});
        setDisableNext (true);
        setTimeLoaded (false);
        setWorkersLoaded (false);
        setTimeIsSelected (false);
        setActiveTime ({value: {hours: -1, minutes: -1}, display: 'Cargando...'});
        setWorkerIsSelected (false);

        getHoursAndStaffByBusinessDate (business.businessId, service.serviceId, event)
        .then (({ hours, staff }) => {
			staff = [{employeeName: locale.stepper.date.anyAvailableLabel, docID: ''}, ...staff];
            if (staff.length === 1) {
                setWorkerOptions ([{employeeName: locale.stepper.date.noneAvailableLabel, docID: ''}]);
            } else {
				if (showEmployees) {
					setWorkerOptions (staff);
				} else {
					setWorkerOptions ([{employeeName: locale.stepper.date.anyAvailableLabel, docID: ''}, {}]);
				}
            }
            
            dispatcher (setHourList (hours));
			setWorkersLoaded (true);
            setTimeLoaded (true);
        })
        .catch ((err) => console.log (err));
    }

    function handleTimeChange (event) {
        const [hours, minutes] = event.target.value.split (':');

        if (timeIsSelected && (activeTime.value.hours === hours && activeTime.value.minutes === minutes)) {
            setActiveTime ({value: {hours: -1, minutes: -1}, display: 'Cargando...'});
            setTimeIsSelected (false);
            return;
        }

        setActiveTime ({value: {hours: hours, minutes: minutes}, display: `${hours}:${minutes}`});
        setActiveDate ({value: activeDate.value.set ('hour', hours).set ('minute', minutes), display: activeDate.display});

        setTimeIsSelected (true);
    }

    function handleSelectWorker (event) {
        const index = event.target.value;

        setActiveWorker (index);
        setWorkerIsSelected (true);

        if (index > 0) {
            setTimeLoaded (false);
            getHoursByBusinessDateStaff (business.businessId, service.serviceId, workerOptions[index], activeDate.value)
            .then ((hours) => {
                dispatcher (setHourList (hours));
                setTimeLoaded (true);
            })
            .catch ((err) => console.log (err));
        } else {
            setTimeLoaded (false);
            getHoursAndStaffByBusinessDate (business.businessId, service.serviceId, activeDate.value)
            .then (({hours, staff}) => {
                setWorkerIsSelected (false);
                dispatcher (setHourList (hours));
                setTimeLoaded (true);
            })
            .catch ((err) => console.log (err));
        }
    }

    async function handleContinue () {
        let employeeData = workerOptions[activeWorker];
        
        if (activeWorker === 0 && workerOptions.length > 1) {
			setWorkersLoaded (false);
			const filteredStaff = await getStaffByBusinessServiceHours (business.businessId, service.serviceId, activeDate.value, activeTime.value)
			employeeData = filteredStaff[Math.floor (Math.random () * filteredStaff.length)];
			setWorkersLoaded (true);
        }

        dispatcher (setDate ({ date: activeDate.value.format ('DD/MM/YYYY'), time: activeTime }));
        dispatcher (setEmployee ({ employeeName: employeeData.employeeName, employeeId: employeeData.docID }));
        dispatcher (increaseStep ());
    }

    return (
        <LoadedComponent loaded={workersLoaded && timeLoaded && workingDaysLoaded}>
            <Box>
                <Stack
                    spacing={3}
                    sx={{overflowX: 'scroll'}}
                >
                    <Typography variant='h6'>{ locale.stepper.date.headingText }</Typography>
                    <Grid container sx={{ '&>.MuiGrid-item': {pl: '0px'}}} justifyContent='center' alignItems='center'>
                        <Grid item xs={12} md={5}>
                            <Typography variant='body1'>{ locale.stepper.date.dateHeadingText }</Typography>
                            <BookeaCalendarPicker activeDate={activeDate.value} handleDateChange={handleDateChange} workDays={workingDays} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid 
                                container 
                                spacing={2}
                                sx={{'&>.MuiGrid-item': {pl: '0px'}}}
                            >
                                <Grid item xs={12}>
                                    <Stack spacing={1} alignItems='center'>
                                        <Typography variant='body1'>{ locale.stepper.date.staffHeadingText }</Typography>
                                        <LabeledSelect inputLabel="Personal" width="80%" left="10%"> 
                                            <Select
                                                value={activeWorker}
                                                defaultValue={activeWorker}
                                                onChange={handleSelectWorker}
                                                label="Personal"    
                                                sx={{width: '80%', margin: 'auto'}}
                                                disabled={workerOptions.length === 1 || !showEmployees}
                                            >
                                                {
                                                    workerOptions.map ((worker, idx) => {
                                                        return <MenuItem key={idx} value={idx}>{worker.employeeName}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </LabeledSelect>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1} alignItems='center'>
                                        <Typography variant='body1'>{ locale.stepper.date.timeHeadingText }</Typography>
                                        <BlocksTimePicker activeTime={activeTime} times={hourList} handleTimeChange={handleTimeChange} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <RegularButton
                                        variant='contained'
                                        onClick={handleContinue}
                                        disabled={disableNext}
                                        sx={{width: '85%'}}
                                    >
                                        { locale.stepper.continueButtonText }
                                    </RegularButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </LoadedComponent>
    );
};

export default ReservationDateForm;