import React from "react";
import { LocalizationProvider, CalendarPicker, StaticTimePicker } from "@mui/x-date-pickers";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import en from "dayjs/locale/en";

import Typography from '@mui/material/Typography';
import { useSelector } from "react-redux";

const BookeaCalendarPicker = (props) => {
	const { language } = useSelector (state => state.page);

	function getDayjsLocale (lang) {
		let dayjsLocale = null;

		switch (lang) {
			case "es":
				dayjsLocale = es;
				break;

			case "en":
				dayjsLocale = en;
				break;

			default:
				dayjsLocale = es;
				break;
		}

		return dayjsLocale;
	}

	function disableDate (date, workDays) {
		const today = dayjs ().set ('hour', 23).set ('minute', 59).set ('second', 59).set ('day', dayjs().day () - 1);
		return date.isBefore (today) || !workDays.includes (date.day());
	}

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getDayjsLocale (language)}>
            <CalendarPicker
                openTo="day"
                date={props.activeDate}
                shouldDisableDate={(date) => disableDate (date, props.workDays)}
                onChange={props.handleDateChange} 
                readOnly={false}
            />
        </LocalizationProvider>
    )
}

const BlocksTimePicker = (props) => {
    function isSelected (time) {
        if (time.hours === props.activeTime.value.hours &&
            time.minutes === props.activeTime.value.minutes) {
                return true;
            }

        return false;
    }

    return (
        <Grid container spacing={2}
            sx={{
                height: '100px', 
                overflow: 'scroll',
                '&>.MuiGrid-item': {pl: '0px'},
                '&::-webkit-scrollbar': {
                    display: 'block',
                    width: '8px',
                    backgroundColor: 'rgba(red, green, blue, 0)'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(red, green, blue, 0)'
                },
                '&::-webkit-scrollbar-corner': {
                    display: 'none'
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '12px',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                },
                maxWidth: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                pl: '10px'
            }}
        >
            {
                props.times.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography variant='body1'>Este empleado no tiene horas disponibles</Typography>
                    </Grid>
                ) : (
                    props.times.map ((time, idx) => {
                        return (
                            <Grid item xs={4} md={3} key={idx}>
                                <Button
                                    variant={isSelected (time) ? 'contained' : 'outlined'}  
                                    onClick={props.handleTimeChange}
                                    value={`${time.hours}:${time.minutes}`}
                                >
                                    {time.hours}:{time.minutes}
                                </Button>
                            </Grid>
                        )
                    })
                )
            }
        </Grid>
    )
}

export { BookeaCalendarPicker, BlocksTimePicker };