import React from 'react'

/* MUI Components */
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton } from '@mui/material';
import ResponsiveDrawer from '../ui/Drawer';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

/* MUI Material Icons */
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { ResponsiveStack } from '../ui/Containers';
import { useNavigate } from 'react-router-dom';


const AdminAppBar = () => {
    const [drawerActive, setDrawerActive] = React.useState (false);
    const stored = localStorage.getItem ('bookea-logged-in');


    function openMenu () {
        setDrawerActive (true);
    }

    function closeMenu () {
        setDrawerActive (false);
    }

    return (
        <React.Fragment>
            <AppBar>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        edge='start'
                        onClick={openMenu}
                        sx={{visibility: stored ? 'visible' : 'hidden'}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='body1'>Administra tus páginas</Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <ResponsiveDrawer
                    open={drawerActive}
                    onClose={closeMenu}
                    sx={{'& .MuiDrawer-paper': { boxSizing: 'border-box'}}}
                >
                <ResponsiveStack
                    spacing={0}
                    sx={{alignItems: 'center', position: 'relative', height: '100%', width: '20vw'}}
                >
                    <Stack
                        sx={{alignItems: 'center', pt: '1em'}}
                    >
                        <Avatar>A</Avatar>
                        <Typography variant='h6'>Admin</Typography>
                    </Stack>
                    <Divider sx={{width: '80%'}}/>
                    <Stack 
                        direction="row" 
                        sx={{alignItems: 'center', height: '100%', width: '100%'}}
                    >
                        <Stack 
                            spacing={2}
                            sx={{width: '100%'}}
                        >
                            <Link href="/" underline='none' sx={{color: 'black', margin: 'auto', width: '100%', textAlign: 'center', pt: '.5em', pb: '.5em', '&:hover': { background: 'gainsboro' }}}>Tus páginas</Link>
                            <Link href="/" underline='none' sx={{color: 'black', margin: 'auto', width: '100%', textAlign: 'center', pt: '.5em', pb: '.5em', '&:hover': { background: 'gainsboro' }}}>Archivos</Link>
                            <Link href="/" underline='none' sx={{color: 'black', margin: 'auto', width: '100%', textAlign: 'center', pt: '.5em', pb: '.5em', '&:hover': { background: 'gainsboro' }}}>Ver reservaciones</Link>
                        </Stack>
                    </Stack>
                    <Button 
                        variant='contained'
                        sx={{position: 'absolute', bottom: '1em'}}
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        <LogoutIcon /> Cerrar Sesión
                    </Button>
                </ResponsiveStack>
            </ResponsiveDrawer>
        </React.Fragment>
    )
}

export default AdminAppBar;