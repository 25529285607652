import { createSlice } from "@reduxjs/toolkit";

export const adminInitialState = {
    uid: '',
    loggedIn: false
};

export const adminSlice = createSlice ({
    name: 'admin',
    initialState: adminInitialState,
    reducers: {
        login: (state, action) => {
            return {
                uid: action.payload.uid,
                loggedIn: true
            };
        },
        logout: () => adminInitialState
    }
});

export const { login, logout } = adminSlice.actions;