const possibleValues = "ABCDEFGHIJKLMNOPQRSTUVWXYZ12345678901234567890"

export function generateCancellationCode () {
    const values = new Uint32Array(6);
    let code = "";

    crypto.getRandomValues(values);
    values.forEach ((value) => {
        const idx = value % possibleValues.length;
        code += possibleValues[idx];
    });

    return code.toUpperCase();
}