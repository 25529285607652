import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

import "../../styles/business/popup.css"

export const ImagePopup = ({ img, close }) => {
	return (
		<div className="popup">
			<img src={img} alt="Popup de promoción" className="popup-image"/>
			<IconButton onClick={close} className="popup-close-button">
				<CloseIcon fontSize="small" className="popup-close-icon"/>
			</IconButton>
		</div>
	)
}