import { getFirestore, collection, getDocs, query, where, setDoc, deleteDoc, doc, addDoc, getCount, limit, startAfter, orderBy } from 'firebase/firestore/lite';
import { signInAnonymously } from 'firebase/auth';

import { app, auth } from '../fb_init';

const db = getFirestore (app);

async function getPageElementsBySubdomain (subdomain) {
    await signInAnonymously (auth);

    const pageCollection = collection (db, 'businessPageCollection');
    return getDocs (query (pageCollection, where ('subdomain', '==', subdomain)));
}


async function getPageElementsById (id) {
    await signInAnonymously (auth);

    const pageCollection = collection (db, 'businessPageCollection');
    return getDocs (query (pageCollection, where ('docID', '==', id)));
}


async function patchPageById (id, body) {
    const pageRef = doc (db, 'businessPageCollection', id);
    return setDoc (pageRef, body);
}


async function createPage (body) {
    const pageCollection = collection (db, 'businessPageCollection');
    try {
        const pageRef = await addDoc (pageCollection, body);

        body.docID = pageRef.id;
        return setDoc (pageRef, body);
    } catch (err) {
        console.log (err);
    }
}


async function deletePageById (id) {
    const res = deleteDoc (doc (db, "businessPageCollection", id));
    return res;
}


async function getAllPagesCount () {
    const pageCollection = collection (db, "businessPageCollection");
    const count = await getCount (pageCollection);

    return count.data().count;
}


async function getAllPages () {
    await signInAnonymously (auth);

    const pageCollection = collection (db, 'businessPageCollection');
    return getDocs (query (pageCollection));
}


async function getPageNextBatch (itemsPerPage, lastItem) {
    const pageCollection = collection (db, "businessPageCollection");
    const pages = lastItem ? await getDocs (query (pageCollection, orderBy ("docID"), startAfter (lastItem), limit (itemsPerPage)))
                        : await getDocs (query (pageCollection, orderBy ('docID'), limit (itemsPerPage)));

    return pages;
}


async function getAllBusinesses () {
    await signInAnonymously (auth);

    const businessCollection = collection (db, 'businessCollection');
    return getDocs (query (businessCollection));
}

export { 
    getPageElementsBySubdomain, 
    getAllPagesCount,
    getPageNextBatch,
    getAllPages, 
    getPageElementsById, 
    patchPageById,
    createPage,
    deletePageById,
    getAllBusinesses
};
