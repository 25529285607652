import React from 'react'

/* Page Components */
import InfoPage from './pages/InfoPage';
import AdminPage from './pages/AdminPage';
import EditPage from './pages/EditPage';
import StatisticsPage from './pages/StatisticsPage';
import CreatePage from './pages/CreatePage';

/* Custom Components */
import AdminAppBar from '../../components/admin/AppBar';

/* Theming functions */
import { getAdminTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material/styles'

/* React-Router-Dom Components */
import { 
    Route,
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';

/* MUI Components */
import Stack from '@mui/material/Stack'

/* API Functions */
import { getPageElementsById } from '../../api/pages/api';
import { useSelector } from 'react-redux';

const AdminProvider = (props) => {
    const [deleteSuccess, setDeleteSuccess] = React.useState (false);
    const [saveSuccess, setSaveSuccess] = React.useState (false);

    const {loggedIn} = useSelector (state => state.admin);
    const stored = localStorage.getItem ('bookea-logged-in');

    let routesElements = (
        <>
            <Route 
                path="/" 
                element={<AdminPage 
                            loggedIn={loggedIn} 
                            deleteSuccess={deleteSuccess}
                            setDeleteSuccess={setDeleteSuccess}
                            saveSuccess={saveSuccess}
                            setSaveSuccess={setSaveSuccess}
                        />} 
            />
            <Route
                path="/page"
                element={<CreatePage loggedIn={stored} setSaveSuccess={setSaveSuccess}/>}
            />
            <Route 
                path="/page/:pageId" 
                element={<InfoPage loggedIn={stored} />}
                loader={({params}) => getPageElementsById (params.pageId)}
            />
            <Route 
                path="/page/:pageId/edit" 
                element={<EditPage loggedIn={stored} setSaveSuccess={setSaveSuccess}/>}
                loader={({params}) => getPageElementsById (params.pageId)}
            />
            <Route 
                path="/page/:pageId/statistics" 
                elements={<StatisticsPage loggedIn={stored} />}
                loader={({params}) => getPageElementsById (params.pageId)}
            />
        </>
    )

    const theme = getAdminTheme ();
    const routes = createRoutesFromElements (routesElements);
    const router = createBrowserRouter (routes);
    return (
        <ThemeProvider theme={theme}>
            <Stack
                sx={{minHeight: '100vh'}}
            >
                <AdminAppBar loggedIn={loggedIn}/>
                <RouterProvider router={router} />
            </Stack>
        </ThemeProvider>
    )
}

export default AdminProvider;