import { Alert, Grid, Typography } from "@mui/material"
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const PageNotFound = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SentimentVeryDissatisfiedIcon />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">Página no encontrada</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Lo sentimos, no reconocemos la URL que has ingresado.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">Si estas intentando ingresar a un URL que incluye un ID, es posible que este ya no exista.</Alert>
            </Grid>
        </Grid>
    )
}

export default PageNotFound;