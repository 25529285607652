import { useEffect, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { RegularButton } from '../../ui/Buttons';
import { setModality } from '../../../redux/business/reservation/reservationSlice';
import { increaseStep } from '../../../redux/business/stepper/stepperSlice';
import { setAppHeight } from '../../../redux/business/page/pageSlice';

const ModalityOptionsForm = () => {
    const [activeOption, setActiveOption] = useState ({value: 1, display: 'En local'});
    const [disableNext, setDisableNext] = useState (false);
    const [showAddressInput, setShowAddressInput] = useState (false);
    const [address, setAddress] = useState (null);

    const dispatcher = useDispatch ();
	const { locale } = useSelector (state => state.page);

    useEffect (() => {
        const app = document.getElementById ('App');
        dispatcher (setAppHeight (app.getBoundingClientRect ().height));
    }, [])

    function handleChange (option) {
        setDisableNext (false);
        setActiveOption ({value: option, display: option === 1 ? locale.stepper.modality.atBusinessText : locale.stepper.modality.atLocationText});
        if (option === 2) {
            setShowAddressInput (true);
            setDisableNext (true);
        } else {
            setShowAddressInput (false);
        }
    }

    function handleChangeAddress (event) {
        setAddress ({value: event.target.value, display: event.target.value});
        setDisableNext (event.target.value.length > 0 ? false : true);
    }

    function handleContinue () {
        dispatcher (setModality ({
            modality: activeOption.display,
            address: address
        }))
        dispatcher (increaseStep ());
    }

    return (
        <Grid
            container
            spacing={3}
            alignItems='center'
            justifyContent='center'
        >
            <Grid item xs={12}>
                <Typography variant='h6'>{ locale.stepper.modality.headingText }</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justifyContent='center' alignItems='center'>
                    <Grid item xs={12}>
                        <RegularButton variant={activeOption.value === 1 ? 'contained' : 'outlined'} sx={{position: 'relative', height: '60px', width: '90%', border: activeOption.value === 1 ? 'solid 2px black' : 'solid 1px grey', /*background: activeOption.value === 1 ? 'gainsboro' : 'none'*/}} onClick={() => handleChange (1)}>
                            <Typography variant='body1' sx={{position: 'absolute', left: '7%'}}>{ locale.stepper.modality.atBusinessText }</Typography>        
                        </RegularButton>
                    </Grid>
                    <Grid item xs={12}>
                        <RegularButton variant={activeOption.value === 2 ? 'contained' : 'outlined'} sx={{position: 'relative', height: '60px', width: '90%', border: activeOption.value === 2 ? 'solid 2px black' : 'solid 1px grey', /*background: activeOption.value === 2 ? 'gainsboro' : 'none'*/}} onClick={() => handleChange (2)}>
                            <Typography variant='body1' sx={{position: 'absolute', left: '7%'}}>{ locale.stepper.modality.atLocationText }</Typography>
                        </RegularButton>
                    </Grid>
                    {
                        showAddressInput === true ? (
                            <Grid item xs={12}>
                                <TextField 
                                    label={locale.stepper.modality.addressLabel}
                                    onChange={handleChangeAddress}
                                    sx={{width: '90%'}}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RegularButton
                    variant='contained'
                    onClick={handleContinue}
                    disabled={disableNext}
                    sx={{width: '90%', marginTop: '1em'}}
                >
                    { locale.stepper.continueButtonText }
                </RegularButton>
            </Grid>
        </Grid>
    )
};

export default ModalityOptionsForm;