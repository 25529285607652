import React from 'react'

import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

const ResponsiveContainerOuter = styled (Container) (({theme}) => ({
    background: theme.palette.secondary.light,
    
    [theme.breakpoints.up ('xs')]: {
        maxWidth: '85vw',
        /*maxHeight: '80vh',*/
        marginBottom: '2.5em',
        marginTop: '1em'
    },
    [theme.breakpoints.up ('sm')]: {
        maxWidth: '80vw',
        /*maxHeight: '80vh',*/
    },
    [theme.breakpoints.up ('md')]: {
        maxWidth: '85vw',
        /*maxHeight: '80vh',*/
    },
    [theme.breakpoints.up ('lg')]: {
        maxWidth: '60vw'
    }
}))

const ResponsiveContainerOuterSolid = styled (Container) (({theme}) => ({
    background: theme.palette.secondary.light.substring (0, 7),
    
    [theme.breakpoints.up ('xs')]: {
        maxWidth: '85vw',
        /*maxHeight: '80vh',*/
        marginBottom: '2.5em',
        marginTop: '1em'
    },
    [theme.breakpoints.up ('sm')]: {
        maxWidth: '80vw',
        /*maxHeight: '80vh',*/
    },
    [theme.breakpoints.up ('md')]: {
        maxWidth: '85vw',
        /*maxHeight: '80vh',*/
    },
    [theme.breakpoints.up ('lg')]: {
        maxWidth: '60vw'
    }
}))

const ResponsiveStack = styled (Stack) (({theme}) => ({
    [theme.breakpoints.up ('xs')]: {
        width: '85vw',
    },
    [theme.breakpoints.up ('sm')]: {
        width: '40vw',
    },
    [theme.breakpoints.up ('md')]: {
        width: '20vw',
    }
}))

export { ResponsiveContainerOuter, ResponsiveContainerOuterSolid, ResponsiveStack };