import React from 'react'

/* MUI Material Components */
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { ResponsiveContainerOuter } from '../../ui/Containers';

const LoginForm = (props) => {
    return (
        <ResponsiveContainerOuter
            sx={{borderRadius: '1em', mt: '1em'}}
        >
            <Grid container
                justifyContent='center'
                alignItems='center'
                sx={{height: '100%'}}
            >
                <Grid item container spacing={3}>
                    <Grid item xs={12}>
                        <TextField label='Usuario' onChange={props.onChangeUsername}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type='password' label='Contraseña' onChange={props.onChangePassword}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained' 
                            onClick={props.onSubmit}
                        >
                            Ingresar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ResponsiveContainerOuter>
    )
}

export default LoginForm;