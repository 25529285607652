import React from 'react';
import { ReactComponent as Success } from '../../media/logo/check-solid.svg';
import '../../styles/business/success.css'

const ImageLogo = (props) => {
    return (
        <a href={props.logoRedirect}>
            <img src={props.logo} alt={props.alt} style={{maxWidth: '200px', maxHeight: '60px'}}/>
        </a>
    );
}

const SuccessLogo = () => {
    return <Success />;
}

export { ImageLogo, SuccessLogo };