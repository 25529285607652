import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { RegularButton } from '../../../components/ui/Buttons';
import { ResponsiveContainerOuter } from '../../../components/ui/Containers'
import { Alert, TextField } from '@mui/material';
import { cancelReservation, sendCancellationEmail, sendCancellationNotification } from '../../../api/reservations/api';
import PageNotFound from '../../PageNotFound';
import { cancellationCodeMatches } from '../../../utils/comparison';
import { validateCancellationCode } from '../../../utils/validation';
import { ReactComponent as BookeaLogo } from '../../../media/logo/Logo SVG Bookea.svg'
import { LoadedComponent } from '../../../components/wrappers';
import dayjs from 'dayjs';
import '../../../styles/business/cancellation.css'


const CancellationPage = () => {
    const loaderData = useLoaderData ();

    const [code, setCode] = useState ('');
    const [cancelled, setCancelled] = useState (false);
    const [error, setError] = useState (false);
    const [errorMessage, setErrorMessage] = useState ('');
    const [loading, setLoading] = useState (false);

    function handleCancellation () {
        setLoading (true);
        if (!validateCancellationCode (code) || !cancellationCodeMatches (code, loaderData)) {
            setError (true);
            setErrorMessage ('Código de cancelación erróneo');
            setLoading (false);
            return;
        }

        cancelReservation (loaderData.docID)
        .then ((res) => {
            if (res.status === 500) {
                setError (true);
                setErrorMessage (res.message);
            }

            /* Cancellation successful */
            sendCancellationEmail ({
                to: loaderData.userEmail,
                toName: loaderData.userName,
                subject: "Cancelación de Reserva",
                variables: {
                    service_name: loaderData.serviceBooked,
                    business_name: loaderData.businessName,
                    booking_id: loaderData.docID,
                    match_date: loaderData.matchDate,
                    start_time: dayjs.unix (loaderData.startTime.seconds).format('HH:mm'),
                    service_duration: dayjs.unix (loaderData.endTime.seconds).diff (dayjs.unix (loaderData.startTime.seconds), 'minute').toString (),
                    employee_name: loaderData.employeeName,
                    service_price: loaderData.amount.toString ()
                }
            })
            .then (res => {
                setCancelled (true);
                setError (false);
                setLoading (false);
            })
            .catch (err => console.error (err));
            
            sendCancellationNotification ({
                template: "cancelled",
                template_variables: {
                    user_name: loaderData.userName,
                    service_name: loaderData.serviceBooked,
                    match_date: loaderData.matchDate,
                    time: loaderData.time
                },
                topic: loaderData.businessID
            });
            
        })
    }
    return loaderData ? (
        <LoadedComponent loaded={!loading}>
            <Grid
                container
                sx={{
                    background: 'url("https://firebasestorage.googleapis.com/v0/b/booking-project-df4f7.appspot.com/o/pageMedia%2Fbarber.jpg?alt=media&token=1a904c51-3208-4475-a5cc-9aff5032067d")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <BookeaLogo id="bookea-logo-cancellation"/>
                    </Grid>
                    <Grid item container xs={12}>
                        <ResponsiveContainerOuter
                            sx={{
                                borderRadius: '3px',
                                pt: '2em',
                                pb: '2em'
                            }}
                        >
                            <Grid item container spacing={3}>
                                {
                                    cancelled ? 
                                    <>
                                        <Grid item xs={12}>
                                            <CheckCircleOutlineIcon />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Tu reserva fue cancelada correctamente</Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Ingresa el código de cancelación para cancelar tu reserva</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={code} onChange={(e) => setCode(e.target.value)} placeholder='Código de 6 dígitos' sx={{minWidth: '40%', maxWidth: '80%'}}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RegularButton variant='contained' onClick={handleCancellation} sx={{minWidth: '40%', maxWidth: '80%'}}>Confirmar cancelación</RegularButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption'>Powered by Bookea</Typography>
                                        </Grid>
                                        {
                                            error && <Grid item xs={12}>
                                                <Alert severity='error'>{errorMessage}</Alert>
                                            </Grid>
                                        }
                                    </>
                                }
                                
                            </Grid>
                        </ResponsiveContainerOuter>
                    </Grid>
                </Grid>
            </Grid>
        </LoadedComponent>
    ) : <PageNotFound />
}

export default CancellationPage;