import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RegularButton } from '../../ui/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { resetStepper } from '../../../redux/business/stepper/stepperSlice';
import { SuccessLogo } from '../Logo';

const SuccessForm = () => {
    const dispatcher = useDispatch ();

	const { locale } = useSelector (state => state.page);

    return (
        <Grid container spacing={1} sx={{height: '40vh'}} alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
                <SuccessLogo />
            </Grid>
			<Grid item xs={12}>
                <Typography variant='h3'>{ locale.stepper.confirmation.headingText }</Typography>
            </Grid>
			<Grid item xs={12}>
				<Typography variant='body1'>{ locale.stepper.confirmation.bodyText }</Typography>
			</Grid>
            <Grid item xs={12}>
                <RegularButton 
                    variant='contained' 
                    onClick={() => dispatcher (resetStepper ())}
                    sx={{
                        width: '90%'
                    }}
                >
                    { locale.stepper.confirmation.newReservationButtonText }
                </RegularButton>
            </Grid>
        </Grid>
    )
}

export default SuccessForm;