import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { signInAnonymously } from 'firebase/auth';

import { app, auth } from '../fb_init';

const storage = getStorage (app);

async function getAllPageFiles () {
    await signInAnonymously (auth);
    
    const listRef = ref (storage, 'pageMedia/');
    const fileList = await listAll (listRef);

    let newOptions = [];
    for (let i = 0; i < fileList.items.length; ++i) {
        const item = fileList.items[i];
        const url = await getDownloadURL (item);
        newOptions.push ({src: url, fileName: item._location.path_.split ('/')[1]});
    }

    return newOptions;
}

export {
    getAllPageFiles
};