import './App.css';
import AdminProvider from './pages/admin/AdminRouter';
import BookingsProvider from './pages/bookings/BookingsRouter';
import BusinessPage from './pages/business/BusinessPage';

import { adminStore, businessStore } from './redux/store';
import { Provider } from 'react-redux';

function App() {
	let subdomain = window.location.hostname.split ('.')[0];
	if (subdomain === 'bookea') {
		window.location.href = 'https://bookeaperu.com';
	}
	
	return (
		<>
			<link rel="icon" href="https://placekitten.com/20/20" />
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
			<div 
				className='App' 
				id='App'
				style={{
					overflowX: 'hidden'
				}}
			>
				{
					subdomain === 'admin' ? (
						<Provider store={adminStore}>
							<AdminProvider />
						</Provider>
					) : subdomain === 'bookings' ? (
						<BookingsProvider />
					) : (
						<Provider store={businessStore}>
							<BusinessPage subdomain={subdomain}/>
						</Provider>
					)
				}
			</div>
		</>
	)
}

export default App;
