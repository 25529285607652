import { configureStore } from "@reduxjs/toolkit";
import { pageSlice } from "./business/page/pageSlice";
import { reservationSlice } from "./business/reservation/reservationSlice";
import { stepperSlice } from "./business/stepper/stepperSlice";
import { userSlice } from "./business/user/userSlice";
import { adminPagesSlice } from "./admin/admin_pages/adminPages";
import { adminSlice } from "./admin/admin/adminSlice";

export const businessStore = configureStore ({
    reducer: {
        page: pageSlice.reducer,
        reservation: reservationSlice.reducer,
        stepper: stepperSlice.reducer,
        user: userSlice.reducer
    },
	devTools: false
});

export const adminStore = configureStore ({
    reducer: {
        adminPages: adminPagesSlice.reducer,
        admin: adminSlice.reducer
    },
	devTools: false
});