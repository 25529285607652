import { createSlice } from "@reduxjs/toolkit";

const stepperInitialState = {
    step: -1,
    stepIndex: -1,
    stepList: [],
    showBack: true
};

export const stepperSlice = createSlice ({
    name: 'stepper',
    initialState: stepperInitialState,
    reducers: {
        increaseStep: state => {
            return {
                ...state,
                stepIndex: state.stepIndex + 1,
                step: state.stepList[state.stepIndex + 1].id
            };
        },
        decreaseStep: state => {
            if (state.stepIndex === 0) {
                return {
                    ...state,
                    stepIndex: - 1,
                    step: -1
                };
            }

            return {
                ...state,
                stepIndex: state.stepIndex - 1,
                step: state.stepList[state.stepIndex - 1].id
            };
        },
        setStepList: (state, action) => {
            return {
                ...state,
                stepList: action.payload
            };
        },
        setShowBack: (state, action) => {
            return {
                ...state,
                showBack: action.payload
            };
        },
        resetStepper: (state) => {
            return {
                ...state,
                step: -1,
                stepIndex: -1,
                showBack: true
            }
        }
    }
});

export const { increaseStep, decreaseStep, setStepList, setShowBack, resetStepper } = stepperSlice.actions;