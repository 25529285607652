import { createSlice } from "@reduxjs/toolkit";

export const adminPagesInitialState = {
    pages: [],
    pagination: 0,
    count: 0,
    references: []
};

export const adminPagesSlice = createSlice ({
    name: 'admin_pages',
    initialState: adminPagesInitialState,
    reducers: {
        setCurrentPages: (state, action) => {
            return {
                ...state,
                pages: action.payload
            };
        },
        setCurrentPagination: (state, action) => {
            return {
                ...state,
                pagination: action.payload
            };
        },
        setCount: (state, action) => {
            return {
                ...state,
                count: action.payload
            };
        },
        addReference: (state, action) => {
            return {
                ...state,
                references: [...state.references, action.payload]
            };
        },
        removeReference: (state, action) => {
            return {
                ...state,
                references: state.references.slice (0, -1)
            };
        },
        setLastItem: (state, action) => {
            return {
                ...state,
                lastItem: action.payload
            }
        },
        resetAdminPages: () => adminPagesInitialState
    }
});

export const { setCurrentPages, setCurrentPagination, setCount, addReference, removeReference, resetAdminPages } = adminPagesSlice.actions;