import React from 'react';

/* Custom Components */
import DataHandler from './DataHandler';
import AuthenticationRequiredPage from '../AuthenticationRequired';

const CreatePage = (props) => {
    
    return (
        <AuthenticationRequiredPage loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}>
            <DataHandler data={null} mode="create" setSaveSuccess={props.setSaveSuccess}/>
        </AuthenticationRequiredPage>
    )
}

export default CreatePage;