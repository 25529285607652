import { useState, useEffect, Fragment } from 'react';

import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { RegularButton } from '../../ui/Buttons';
import LabeledSelect from '../../ui/Select';

import { getServicesByBusinessId } from '../../../api/reservations/api';
import { LoadedComponent } from '../../wrappers';
import { useDispatch, useSelector } from 'react-redux';
import { serviceInitialState, setService, setServiceList } from '../../../redux/business/reservation/reservationSlice';
import { increaseStep } from '../../../redux/business/stepper/stepperSlice';
import { setAppHeight } from '../../../redux/business/page/pageSlice';
import { arrIsEmpty } from '../../../utils/validation';
import { servicesAreEqual } from '../../../utils/comparison';

const ServiceOptionsForm = () => {
    const [selectedValue, setSelectedValue] = useState (0);
    const [loaded, setLoaded] = useState (true);

    const dispatcher = useDispatch ();
	const { locale } = useSelector (state => state.page);
    const { serviceList } = useSelector (state => state.reservation);
    const { businessId } = useSelector (state => state.reservation.business);

    useEffect (() => {
        const app = document.getElementById ('App');
        dispatcher (setAppHeight (app.getBoundingClientRect ().height));

		setLoaded (false);
		getServicesByBusinessId (businessId)
		.then ((res) => {
			let newOptions = [];

			res.forEach ((doc) => {
				newOptions.push (doc.data ());
			});
			
			newOptions = newOptions.sort ((a, b) => a.time - b.time);
			dispatcher (setServiceList (newOptions));
			setLoaded (true);
		})
    }, [])

    useEffect (() => {
        if (loaded) {
            setSelectedValue (0);
        }
    }, [serviceList]);

    function handleSelect (event) {
        setSelectedValue (event.target.value);
    };

    function handleContinue () {
        const selectedService = serviceList[selectedValue];

        dispatcher (setService ({
            serviceId: selectedService.docID,
            serviceDuration: selectedService.serviceDuration,
            price: selectedService.isOnDiscount ? selectedService.discountPrice : selectedService.price,
            name: selectedService.serviceName
        }));
        dispatcher (increaseStep ());
    }

    return (
        <LoadedComponent loaded={loaded}>
            <Grid container spacing={3} sx={{height: '100%', overflowX: 'scroll'}} justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <Typography variant='h6'>{ locale.stepper.service.headingText }</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabeledSelect inputLabel="Servicio" width="100%" left="5%">
                        <Select
                            value={selectedValue}
                            label={locale.stepper.service.selectLabel}
                            onChange={handleSelect}
                            defaultValue={selectedValue}
                            sx={{
                                minWidth: '90%',
                                maxWidth: '90%',
                                margin: 'auto'
                            }}
                            disabled={serviceList.length === 0}
                        >
                            {
                                serviceList.map ((option, idx) => {
                                    return <MenuItem key={idx} value={idx}>{option.serviceName}</MenuItem>
                                })
                            }
                        </Select>
                    </LabeledSelect>
                </Grid>
                {
                    serviceList.length > 0 ? (
                        <Grid item container xs={12} sx={{height: '100px'}}>
                            <Grid item xs={8}>
                                <Typography variant='body1' textAlign='left' pl='7.5%'>{serviceList[selectedValue].description}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1' textAlign='right' pr='15%'>{ locale.stepper.service.priceLabel }: S/.{serviceList[selectedValue].isOnDiscount ? <><s>{serviceList[selectedValue].price}</s> {serviceList[selectedValue].discountPrice}</> : serviceList[selectedValue].price}</Typography>
								<br></br>
								<Typography variant='body1' textAlign='right' pr='15%'>{ locale.stepper.service.durationLabel }: {serviceList[selectedValue].serviceDuration} min</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Fragment></Fragment>
                    )
                }
                <Grid item xs={12} md={12}>
                    <RegularButton
                        variant='contained'
                        onClick={handleContinue}
                        disabled={serviceList.length === 0}
                        sx={{width: '90%'}}
                    >
                        { locale.stepper.continueButtonText }
                    </RegularButton>
                </Grid>
            </Grid>
        </LoadedComponent>
    )
};

export default ServiceOptionsForm;