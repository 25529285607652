import React from 'react';

/* Mui Material Components */
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CheckBox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';

/* MUI Icons Material */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

/* Aux. Imports */
import { reservationPages } from '../../reservations/Form';


const AdminEditForm = (props) => {
    const businessId = props.controls.businessID;
    const businessName = props.controls.businessName;
    const subdomain = props.controls.subdomain;
    const steps = props.controls.steps;
    const stepsChanged = props.controls.stepsChanged;
	const showPopup = props.controls.showPopup;
	const popup = props.controls.popup;
    const primaryMain = props.controls.primaryMain;
    const primaryLight = props.controls.primaryLight;
    const primaryDark = props.controls.primaryDark;
    const primaryOpacity = props.controls.primaryOpacity;
    const secondaryMain = props.controls.secondaryMain;
    const secondaryLight = props.controls.secondaryLight;
    const secondaryDark = props.controls.secondaryDark;
    const secondaryOpacity = props.controls.secondaryOpacity;
    const stepperPrimary = props.controls.stepperPrimary;
    const stepperSecondary = props.controls.stepperSecondary;
    const background = props.controls.background;
    const logo = props.controls.logo;
    const pageUrl = props.controls.pageUrl;
    const typography = props.controls.typography;

    function isStep (stepNumber) {
        let present = false;
        const stepList = steps.value;
        stepList.forEach ((step) => {
            if (step.id === stepNumber) {
                present = true;
            }
        });

        return present;
    }

    function handleCheck (stepNumber) {
        if (isStep (stepNumber)) {
            let newSteps = steps.value.filter ((step) => step.id != stepNumber);
            steps.setter (newSteps);
            stepsChanged.setter (!stepsChanged.value);
            
        } else {
            let newSteps = steps.value;
            let addedStep;

            switch (stepNumber) {
                case reservationPages.HEADQUARTERS:
                    addedStep = {
						id: 0, 
						label: {
							es: 'Sedes Disponibles',
							en: 'Available Locations'
						}
					};
                    break;

                case reservationPages.SERVICE:
                    addedStep = {
						id: 1, 
						label: {
							es: 'Servicios Ofrecidos',
							en: 'Available Services'
						}
					};
                    break;

                case reservationPages.MODALITY:
                    addedStep = {
						id: 2,
						label: {
							es: 'Modalidad de Servicio',
							en: 'Modality of Service'
						}
					};
                    break;

                case reservationPages.DATE:
                    addedStep = {
						id: 3,
						label: {
							es: 'Fechas Disponibles',
							en: 'Available Dates'
						}
					};
                    break;

                case reservationPages.USERDATA:
                    addedStep = {
						id: 4,
						label: {
							es: 'Datos de Contacto',
							en: 'Contact Information'
						},
						fields: {
							fullName: true,
							phone: true,
							email: true,
							dni: true
						}
					};
                    break;

                case reservationPages.SUCCESS:
                    addedStep = {
						id: 5,
						label: {
							es: 'Confirmación',
							en: 'Confirmation'
						}
					};
                    break;
            }

            newSteps.push (addedStep);
            newSteps.sort ((stepA, stepB) => stepA.id > stepB.id ? 1 : stepA.id < stepB.id ? -1 : 0);
            steps.setter (newSteps);
            stepsChanged.setter (!stepsChanged.value);
        }
    }

    function handleTypographyChange (event) {
        const field = event.target.name.split ('-')[0];
        const tag = event.target.name.split ('-')[1];

        if (field === 'font') {
            typography.value[tag].fontFamily = event.target.value;
        } else {
            typography.value[tag].fontURL = event.target.value;
        }

        typography.setter (typography.value);
    }

    return (
        <React.Fragment>
            <Stack 
                spacing={2}
                sx={{
                    alignItems: 'center',
                    pb: '1em',
                    pt: '1em'
                }}
            >
                <Box
                    sx={{width: '70vw'}}
                >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>General</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Nombre de la página"
                                        onChange={businessName.setter}
                                        defaultValue={businessName.value}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        value={businessId.value}
                                        label='Empresa'
                                        onChange={businessId.setter}
                                        placeholder='Selecciona una empresa'
                                        sx={{minWidth: '100px', maxWidth: '250px'}}
                                        multiple
                                    >
                                        {
                                            props.businesses.map ((bsns, idx) => {
                                                return (
                                                    <MenuItem key={idx} value={bsns.docID}>{bsns.businessName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Sub-Dominio"
                                        onChange={subdomain.setter}
                                        defaultValue={subdomain.value}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>.bookea.page</InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Proceso de Reserva</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack spacing={2}>
                                        <Typography variant='h6'>Pasos/Formularios para esta página</Typography>
                                        <FormGroup>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <Stack spacing={2} sx={{alignItems: 'center'}}>
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.HEADQUARTERS)} value={true} onChange={(e) => handleCheck (reservationPages.HEADQUARTERS)} />} label="Sede" />
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.SERVICE)} />} value={true} onChange={(e) => handleCheck (reservationPages.SERVICE)} label="Servicio" />
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.MODALITY)} />} value={true} onChange={(e) => handleCheck (reservationPages.MODALITY)} label="Modalidad" />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack spacing={2} sx={{alignItems: 'center'}}>
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.DATE)}  value={true} onChange={(e) => handleCheck (reservationPages.DATE)} />} label="Fecha" />
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.USERDATA)} value={true} onChange={(e) => handleCheck (reservationPages.USERDATA)} />} label="Datos de Contacto" />
                                                        <FormControlLabel control={<CheckBox checked={isStep (reservationPages.SUCCESS)} value={true} onChange={(e) => handleCheck (reservationPages.SUCCESS)} />} label="Confirmación" />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </FormGroup>
										<Typography variant='h6'>Pop-up de promoción</Typography>
											<Grid container spacing={3}>
												<Grid item xs={6}>
													<FormControlLabel control={<CheckBox checked={showPopup.value} value={true} onChange={showPopup.setter} />} label="Mostrar Pop-up" />
												</Grid>
												<Grid item xs={6}>
													<Select
														sx={{minWidth: '100px'}}
														value={popup.value}
														onChange={popup.setter}
														label='Imagen de Pop-up'
													>
														{
															props.files.map ((file, idx) => {
																return (
																	<MenuItem key={idx} value={file.src}>{file.fileName}</MenuItem>
																)
															})
														}
													</Select>
												</Grid>
											</Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Tema</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stack spacing={2} sx={{alignItems: 'center'}}>
                                        <Typography variant='h6'>Color Primario</Typography>                    
                                        <Stack direction="row" spacing={3}>
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value: primaryMain.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={primaryMain.setter} 
                                                        />
                                                } 
                                                label="Principal" 
                                            />
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value: primaryLight.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={primaryLight.setter} 
                                                        />
                                                } 
                                                label="Claro" 
                                            />
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value: primaryDark.value}} 
                                                            sx={{width: '2em'}} 
                                                            onChange={primaryDark.setter} 
                                                        />
                                                } 
                                                label="Oscuro" 
                                            />
                                            <Box>
                                                <Typography variant='body1'>Opacidad</Typography>
                                                <Slider min={0} max={100} defaultValue={primaryOpacity.value} sx={{width: '100px'}} valueLabelDisplay="auto" onChange={primaryOpacity.setter} />
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={3} sx={{alignItems: 'center'}}>
                                        <Typography variant='h6'>Color Secundario</Typography>
                                        <Stack direction="row" spacing={3}>
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value :secondaryMain.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={secondaryMain.setter} 
                                                        />
                                                } 
                                                label="Principal" 
                                            />
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value: secondaryLight.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={secondaryLight.setter} 
                                                        />
                                                } 
                                                label="Claro" 
                                            />
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value: secondaryDark.value}} 
                                                            sx={{width: '2em'}} 
                                                            onChange={secondaryDark.setter} 
                                                        />
                                                } 
                                                label="Oscuro" 
                                            />
                                            <Box>
                                                <Typography variant='body1'>Opacidad</Typography>
                                                <Slider min={0} max={100} defaultValue={secondaryOpacity.value} sx={{width: '100px'}} valueLabelDisplay="auto" onChange={secondaryOpacity.setter}/>
                                            </Box>
                                            
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={3} sx={{alignItems: 'center'}}>
                                        <Typography variant='h6'>Color del Stepper</Typography>
                                        <Stack direction="row" spacing={3}>
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value :stepperPrimary.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={stepperPrimary.setter} 
                                                        />
                                                } 
                                                label="Principal" 
                                            />
                                            <FormControlLabel 
                                                control={<Input 
                                                            inputProps={{type: 'color', value :stepperSecondary.value}} 
                                                            sx={{width: '2em'}}  
                                                            onChange={stepperSecondary.setter} 
                                                        />
                                                } 
                                                label="Secundario" 
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography variant='h6'>Imagen de fondo</Typography>
                                            <Select
                                                value={background.value}
                                                onChange={background.setter}
                                                label='Imagen de fondo'
                                            >
                                                {
                                                    props.files.map ((file, idx) => {
                                                        return (
                                                            <MenuItem key={idx} value={file.src}>{file.fileName}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='h6'>Logo</Typography>
                                            <Select
                                                value={logo.value}
                                                onChange={logo.setter}
                                                label='Logo'
                                            >
                                                {
                                                    props.files.map ((file, idx) => {
                                                        return (
                                                            <MenuItem key={idx} value={file.src}>{file.fileName}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='h6'>URL de la empresa</Typography>
                                            <TextField value={pageUrl.value} label='URL de la empresa' onChange={pageUrl.setter} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Company</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.company.fontFamily} label='Font Family' name='font-company' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.company.fontURL} label='Font URL' name='url-company' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H1</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h1.fontFamily} label='Font Family' name='font-h1' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h1.fontURL} label='Font URL' name='url-h1' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H2</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h2.fontFamily} label='Font Family' name='font-h2' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h2.fontURL} label='Font URL' name='url-h2' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H3</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h3.fontFamily} label='Font Family' name='font-h3' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h3.fontURL} label='Font URL' name='url-h3' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H4</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h4.fontFamily} label='Font Family' name='font-h4' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h4.fontURL} label='Font URL' name='url-h4' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H5</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h5.fontFamily} label='Font Family' name='font-h5' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h5.fontURL} label='Font URL' name='url-h5' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>H6</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h6.fontFamily} label='Font Family' name='font-h6' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.h6.fontURL} label='Font URL' name='url-h6' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Body1</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.body1.fontFamily} label='Font Family' name='font-body1' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.body1.fontURL} label='Font URL' name='url-body1' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Body2</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.body2.fontFamily} label='Font Family' name='font-body2' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.body2.fontURL} label='Font URL' name='url-body2' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Button</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.button.fontFamily} label='Font Family' name='font-button' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.button.fontURL} label='Font URL' name='url-button' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Caption</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.caption.fontFamily} label='Font Family' name='font-caption' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.caption.fontURL} label='Font URL' name='url-caption' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                                        <Grid item xs={2}>
                                            <Typography variant='h6'>Overline</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.overline.fontFamily} label='Font Family' name='font-overline' onChange={handleTypographyChange}/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField value={typography.value.overline.fontURL} label='Font URL' name='url-overline' onChange={handleTypographyChange}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Button 
                    variant='contained'
                    onClick={props.onSubmit}
                    sx={{maxWidth: '100px'}}
                >
                    Guardar
                </Button>
            </Stack>
        </React.Fragment>
    );
}

export default AdminEditForm;