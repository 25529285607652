import { getFirestore, collection, getDocs, where, query, doc, addDoc, setDoc, deleteDoc } from 'firebase/firestore/lite';
import { signInAnonymously } from 'firebase/auth';

import { app, auth } from '../fb_init';
import axios from 'axios';

const db = getFirestore (app);

/* ------------ Aux. Functions ------------ */
export function numberToDayField (number) {
    let dayField;

    switch (number) {
        case 1:
            dayField = 'monday';
            break;

        case 2:
            dayField = 'tuesday';
            break;

        case 3:
            dayField = 'wednesday';
            break;

        case 4:
            dayField = 'thursday';
            break;

        case 5:
            dayField = 'friday';
            break;

        case 6:
            dayField = 'saturday';
            break;

        case 7:
            dayField = 'sunday';
            break;

        default:
            dayField = 'monday';
            break;
    }

    return dayField;
}

/* ------------ Fetching functions ------------ */
function getAllBusinesses () {
    const businessesCollection = collection (db, 'businessCollection');
    getDocs (businessesCollection)
    .then ((docs) => {
        //docs.map ((doc) => console.log (doc))
    })
    .catch ((err) => console.log (err));
}

async function getBusinessById (id) {
    await signInAnonymously (auth);

    const businessesCollection = collection (db, 'businessCollection');
    const businesses = await getDocs (query (businessesCollection, where ('docID', '==', id)));

    let business;

    businesses.forEach ((doc) => {
        business = doc.data ();
    })

    return business;
}

async function getMultipleBusinessByIdArray (idArray) {
    await signInAnonymously (auth);

    const businesses = [];
    let response;

    const businessCollection = collection (db, 'businessCollection');
    
    for (let i = 0; i < idArray.length; i += 10) {
        response = await getDocs (query (businessCollection, where ('docID', 'in', idArray.slice(i, i + 10))));
        response.forEach (business => businesses.push (business.data()));
    }

    return businesses;
}

async function getServicesByBusinessId (id) {
    await signInAnonymously (auth);

    const servicesCollection = collection (db, 'businessServicesCollection');
    return getDocs (query (servicesCollection, where ('businessID', '==', id)));
}

async function getHoursAndStaffByBusinessDate (businessId, serviceId, date) {
    const res = await axios ({
        url: `${process.env.REACT_APP_BOOKEA_RESERVATION_API_URL}/staff-hours/business/${businessId}/service/${serviceId}`,
		params: {
			date: date.format ("DD-MM-YYYY")
		},
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    });

	return res.data;
}

async function getHoursByBusinessDateStaff (businessId, serviceId, staff, date=null) {
	const res = await axios ({
		url: `${process.env.REACT_APP_BOOKEA_RESERVATION_API_URL}/hours/business/${businessId}/service/${serviceId}/staff/${staff.docID}`,
		params: date && {
			date: date.format ("DD-MM-YYYY")
		},
		method: "get",
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
		}
	});

	return res.data;
}

async function getStaffByBusinessServiceHours (businessId, serviceId, date, time=null) {
    let params = {
		date: date.format ("DD-MM-YYYY")
	};

	if (time) {
		params.time = `${time.hours}-${time.minutes}`
	}
	
	const res = await axios ({
        url: `${process.env.REACT_APP_BOOKEA_RESERVATION_API_URL}/available-staff/business/${businessId}/service/${serviceId}`,
		params: params,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    });

	return res.data;
}

async function getDaysByBusinessId (businessId) {
    const res = await axios ({
        url: `${process.env.REACT_APP_BOOKEA_RESERVATION_API_URL}/days/business/${businessId}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    });

	return res.data;
}

async function getReservationById (reservationId) {
    await signInAnonymously (auth);

    try {
        const bookingCollection = collection (db, 'bookingCollection');
        const bookingRef = await getDocs(query(bookingCollection, where('docID', '==', reservationId)));
        let reservationData;

        bookingRef.forEach ((reservation) => {
            reservationData = reservation.data ();
        })
        
        return reservationData ? reservationData : null;
    } catch (err) {
        console.error (err);
    }
}

async function postReservation (reservationData) {
    return axios ({
		url: `${process.env.REACT_APP_BOOKEA_RESERVATION_API_URL}`,
		method: "post",
		data: JSON.stringify (reservationData),
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
		}
	});
}

async function cancelReservation (reservationId) {
    await signInAnonymously (auth);
    
    try {
        const bookingCollection = collection (db, 'bookingCollection');
        const res = await deleteDoc (doc (db, 'bookingCollection', reservationId));
        return {status: 200, message: 'Deleted'};
    } catch (err) {
        console.log (err);
        return {status: 500, message: 'Error'};
    }
}

function sendReservationEmail (emailBody) {
    return axios ({
        url: process.env.REACT_APP_BOOKEA_MAIL_RESERVATION_URL,
        method: 'post',
        data: emailBody,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    })
}

function sendConfirmationEmail (emailBody) {
    return axios ({
        url: process.env.REACT_APP_BOOKEA_MAIL_CONFIRMATION_URL,
        method: 'post',
        data: emailBody,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    })
}

async function sendCancellationEmail (emailBody) {
    const emailRes = await axios ({
        url: process.env.REACT_APP_BOOKEA_MAIL_CANCELLATION_URL,
        method: 'post',
        data: emailBody,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    })

    if (emailRes.status !== 202) {
        throw new Error ('Email server responded with an error.');
    }

    return emailRes;
}

async function sendReservationNotification (notificationBody) {
    const notificationRes = await axios ({
        url: process.env.REACT_APP_BOOKEA_NOTIFICATION_RESERVATION_URL,
        method: 'post',
        data: notificationBody,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    })

    if (notificationRes.status !== 200) {
        throw new Error ('Notification server responded with an error');
    }

    return notificationRes;
}

async function sendCancellationNotification (notificationBody) {
    const notificationRes = await axios ({
        url: process.env.REACT_APP_BOOKEA_NOTIFICATION_CANCELLATION_URL,
        method: 'post',
        data: notificationBody,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_BOOKEA_MAIL_API_TOKEN}`
        }
    });
}

export { 
    getAllBusinesses, 
    getBusinessById, 
    getMultipleBusinessByIdArray,
    getServicesByBusinessId, 
    getHoursAndStaffByBusinessDate,
    getHoursByBusinessDateStaff,
    getStaffByBusinessServiceHours,
    getDaysByBusinessId,
    getReservationById,
    postReservation,
    cancelReservation,
    sendReservationEmail,
    sendCancellationEmail,
    sendConfirmationEmail,
    sendReservationNotification,
    sendCancellationNotification
};