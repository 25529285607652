/* React-Router-Dom Components */
import { 
    Route,
    RouterProvider, 
    createRoutesFromElements,
    createBrowserRouter
} from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { getReservationById } from '../../api/reservations/api';
import { getAdminTheme } from '../../styles/theme';

import CancellationPage from './pages/Cancellation';
import PageNotFound from '../PageNotFound';

const routesElements = (
    <>
        <Route 
            path="/cancel/:reservationId" 
            element={<CancellationPage />} 
            loader={({params}) => getReservationById (params.reservationId)}
            errorElement={<PageNotFound />}
        />
    </>
)

const theme = getAdminTheme ();
const routes = createRoutesFromElements (routesElements);
const router = createBrowserRouter (routes);

const BookingsProvider = () => {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    )
}

export default BookingsProvider;