import React from 'react';
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const FilledIconButton = styled(IconButton) (({ theme }) => {
    return {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {backgroundColor: theme.palette.primary.dark},
        '&:disabled': {backgroundColor: 'lightgrey', color: 'grey'}
    }
});

const RegularButton = styled (Button) (({theme}) => ({
    borderRadius: '3px',
    textTransform: 'capitalize',
    paddingTop: '1em',
    paddingBottom: '1em'
}))

export {FilledIconButton, RegularButton};