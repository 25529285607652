import React from 'react';

import { styled } from '@mui/material/styles';
import { LocationDisplayMap } from '../reservations/LocationPicker';

const ResponsiveLocationDisplayMap = styled (LocationDisplayMap) (({ theme }) => ({
    [theme.breakpoints.down ('md')]: {
        width: '100px',
        height: '300px'
    },
    [theme.breakpoints.up ('md')]: {
        width: '400px',
        height: '300px'
    }
}));

export default ResponsiveLocationDisplayMap;